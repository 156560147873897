import { Flex, styled } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  alignSelf: 'center',
  width: '100%',
  height: '100%',
  padding: '$5 0',

  svg: {
    width: 56,
    height: 56,
  },
})

export { Container }
