import { styled } from '@grupoboticario/flora-react'

const BadgeContainer = styled('span', {
  color: '$actionableContent',
  fontSize: '$restricted',
  position: 'absolute',
  padding: '$1',
  borderRadius: '$medium',
  border: '2px solid $nonInteractiveAltPredominant',
  backgroundColor: '$nonInteractiveEmphasis',
  boxSizing: 'content-box',
  top: '-10px',
  right: '-10px',
})

const Container = styled('div', {
  position: 'relative',
})

export { BadgeContainer, Container }
