import { createContext, type PropsWithChildren } from 'react'

import { type API } from './api.types'

interface ApiProviderProps extends PropsWithChildren {
  api: API
}

const ApiProvider: React.FC<ApiProviderProps> = ({ children, api }) => (
  <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
)

const ApiContext = createContext<API | null>(null)

export { ApiProvider, ApiContext }
