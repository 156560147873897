import { Hidden } from '@grupoboticario/flora-react'
import { Table } from '@grupoboticario/flora-team-react-table'

import { DataTableOrder, DataTableTextColumn } from '../data-table.types'
import { useTableContext, useTableHeaderContext } from '../table-provider'
import { HeaderCellBase, HeaderCellCheckbox } from './header-cell'
import { TableHead } from './table-header.styles'

const TableHeader = (): JSX.Element => {
  const { columns, records, ordination, pagination } = useTableContext()
  const { sort, setSort } = useTableHeaderContext()

  const getSortOrder = (column: DataTableTextColumn<unknown>): DataTableOrder | undefined => {
    if (!column.ordenable) return
    return column.key === sort.key ? sort.order : 'none'
  }

  const sortRecords = (column: DataTableTextColumn<unknown>) => (): void => {
    if (!column.ordenable) {
      return
    }

    const currentSort = { ...sort }

    if (currentSort.key !== column.key) {
      currentSort.key = column.key
      currentSort.order = 'desc'
    } else {
      if (currentSort.order === 'desc') currentSort.order = 'asc'
      else if (currentSort.order === 'asc') currentSort.order = 'none'
      else currentSort.order = 'desc'
    }

    ordination.onOrdinate(currentSort.key, currentSort.order)
    pagination.onPaginate(1)
    setSort(currentSort)
  }

  return (
    <TableHead>
      <Table.Row>
        <HeaderCellCheckbox records={records} />

        {columns.map((column, index) => (
          <Hidden only={column.hidden} key={index}>
            {column.type === 'text' ? (
              <HeaderCellBase
                column={column}
                order={getSortOrder(column)}
                onSort={sortRecords(column)}
              />
            ) : (
              <HeaderCellBase column={column} />
            )}
          </Hidden>
        ))}
      </Table.Row>
    </TableHead>
  )
}

export { TableHeader }
