import { type DataTableOrder, type DataTableOrdination } from '@shared/components'
import { useFiltersContext } from '@shared/providers'

import { type Reseller } from '../reseller-list.types'

const useOrdination = (): DataTableOrdination<Reseller> => {
  const { changeOrdination } = useFiltersContext()

  const onOrdinate = (sortField: string, sortOrder: DataTableOrder): void => {
    if (sortOrder === 'none') {
      changeOrdination({ sortField: '', sortOrder: '' })
    } else {
      const ordination = { sortField, sortOrder }
      changeOrdination(ordination)
    }
  }

  return {
    onOrdinate,
  }
}

export { useOrdination }
