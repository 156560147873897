import { useMemo } from 'react'

import { useSectorsContext, useSectorsSelectedContext } from '../providers'
import { type Sector } from '../sectors-filter.types'

type UsePortfolio = () => {
  responsible: Sector[]
  coresponsible: Sector[]
  hasResponsible: boolean
  hasCoresponsible: boolean
}

const usePortfolio: UsePortfolio = () => {
  const { items } = useSectorsContext()
  const { sectorsSelected } = useSectorsSelectedContext()

  return useMemo(() => {
    const actives = items.filter((s) => sectorsSelected.includes(s.sectorId))

    return {
      responsible: actives.filter((s) => s.responsibility === 'RESPONSIBLE'),
      coresponsible: actives.filter((s) => s.responsibility === 'CORRESPONSIBLE'),
      hasResponsible: actives.some((s) => s.responsibility === 'RESPONSIBLE'),
      hasCoresponsible: actives.some((s) => s.responsibility === 'CORRESPONSIBLE'),
    }
  }, [items, sectorsSelected])
}

export { usePortfolio, type UsePortfolio }
