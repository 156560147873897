import React from 'react'

import { FloraCheckbox as Checkbox, Flex, Text } from '@grupoboticario/flora-react'

import { type ObjectivesFormHandler } from '@objectives-filter/objectives-filter.types'
import { type FilterFieldCheckbox, type FilterFieldOption } from '@shared/providers'

type CheckboxFieldProps = FilterFieldCheckbox & ObjectivesFormHandler<string | boolean>

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  onChange,
  filters,
  options,
  label,
  form,
}) => {
  const [filter] = filters
  const value = form[filter.name]

  const handleChange = (option: FilterFieldOption): void => {
    if (option.value === value) {
      onChange({ [filter.name]: '' })
    } else {
      onChange({ [filter.name]: option.value })
    }
  }

  const getFieldId = (unique: string): string => `${filter.name}-${unique}`

  return (
    <Flex direction="column" gap="$2">
      <Text size="bodySmallStandardRegular">{label}</Text>

      <Flex gap="$4" css={{ paddingLeft: '$1' }}>
        {options.map((option) => {
          const id = getFieldId(option.value)

          return (
            <Flex gap="$2" key={id} align="center">
              <Checkbox
                id={id}
                role="option"
                value={option.value}
                checked={option.value === value}
                onChange={() => handleChange(option)}
                label={option.label}
              />
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}

export { CheckboxField }
