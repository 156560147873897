import { Flex, styled } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: '$backgroundAltPrimary',
})

export { Container }
