import { Information } from '@shared/components'
import { pt } from '@shared/locales'

import { ResellerAttendanceStatus } from '../general.types'
import { Container } from './situation.styles'

const messageHash: Record<string, string | null> = {
  [ResellerAttendanceStatus.NOT_IN_STRUCTURE]: 'notInStructure',
  [ResellerAttendanceStatus.WITHOUT_STRUCTURE]: 'withoutStructure',
  [ResellerAttendanceStatus.IN_ATTENDANCE_BY_OTHER_SUPERVISOR]: 'inAttendance',
  [ResellerAttendanceStatus.ATTENDANCE_RELEASED]: null,
  [ResellerAttendanceStatus.IN_ATTENDANCE_BY_YOURSELF]: null,
}

interface ResellerStatusesProps {
  isBlocked?: boolean
  status: ResellerAttendanceStatus | undefined
  canAttend: boolean
}

const Situation = ({ status, isBlocked, canAttend }: ResellerStatusesProps): JSX.Element => {
  const messageStatus: string | null = status ? messageHash[status] : null
  const statusHash: Record<string, string> = pt.generalDataInformations.status
  const message: string = messageStatus ? statusHash[messageStatus] : ''

  return (
    <Container>
      {messageStatus && <Information status="warning" message={message} />}
      {canAttend && isBlocked && (
        <Information status="error" message={pt.generalDataInformations.status.blocked} />
      )}
    </Container>
  )
}

export { Situation }
