import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  type PropsWithChildren,
} from 'react'

import { useApiData, type UseApiDataOutput } from '@shared/api'
import { useFiltersContext } from '@shared/providers'

import { useStorage } from '@shared/hooks'
import { type Sector, type SectorDTO } from '../sectors-filter.types'

const SectorsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [sectorsSelected, setSectorsSelected] = useState<number[]>([])
  const { changeFilters } = useFiltersContext()
  const sectorsStg = useStorage<number[]>('segmentation:sectors')

  const { data, ...others } = useApiData<SectorDTO>({
    dataSource: 'getSectors',
    onSuccess: ({ data: sectors }) => {
      const sectorIdStored = sectorsStg.get()
      const sectorIdResponse = sectors.map((s) => s.sectorId)
      let sectorId = sectorIdResponse.filter((s) => sectorIdStored?.includes(s))
      if (sectorId.length <= 0) sectorId = sectorIdResponse
      changeFilters({ sectorId })
      setSectorsSelected(sectorId)
    },
  })

  return (
    <SectorsContext.Provider value={{ items: data?.data ?? [], ...others }}>
      <SectorsSelectedContext.Provider value={{ sectorsSelected, setSectorsSelected }}>
        {children}
      </SectorsSelectedContext.Provider>
    </SectorsContext.Provider>
  )
}

type SectorsContextProps = Omit<UseApiDataOutput<SectorDTO>, 'data'> & {
  items: Sector[]
}

type sectorsSelectedContextProps = {
  sectorsSelected: number[]
  setSectorsSelected: Dispatch<SetStateAction<number[]>>
}

const SectorsContext = createContext<null | SectorsContextProps>(null)
const SectorsSelectedContext = createContext<sectorsSelectedContextProps>({
  sectorsSelected: [],
  setSectorsSelected: () => {},
})

const useSectorsContext = (): SectorsContextProps => {
  const context = useContext(SectorsContext)
  if (!context) throw new Error('Sectors filter provider not found')
  return context
}

const useSectorsSelectedContext = (): sectorsSelectedContextProps => {
  return useContext(SectorsSelectedContext)
}

export { SectorsProvider, useSectorsContext, useSectorsSelectedContext, type SectorsContextProps }
