import { type PropsWithChildren } from 'react'

import { BadgeContainer, Container } from './badge.styles'

interface BadgeProps extends PropsWithChildren {
  text?: string
  show?: boolean
}

const Badge: React.FC<BadgeProps> = ({ children, text, show }) => (
  <Container>
    {show && <BadgeContainer>{text}</BadgeContainer>}
    {children}
  </Container>
)

export { Badge }
