import { FloraButton as Button, Flex, Text } from '@grupoboticario/flora-react'
import { QuestionAnswerIcon } from '@grupoboticario/flora-react-icons'

import { useEvents } from '@shared/events'
import { pt } from '@shared/locales'

import { machine } from '../../../config/steps'
import { useRegisterSteps } from '../../../hooks'
import { DispatchStepContainer } from './dispatch-step.styles'

const DispatchStep: React.FC = () => {
  const events = useEvents()
  const steps = useRegisterSteps()

  const onClickButton = (): void => {
    steps.setList(machine.send.on.next)
    events.clickRegisterTrigger()
  }

  return (
    <Flex justify="center">
      <DispatchStepContainer>
        <QuestionAnswerIcon size={56} color="$actionableAltContent" />

        <Text size="bodyLargeShortMedium" weight="medium" align="center">
          {pt.activationRegister.dispatchStep.register}
        </Text>

        <Flex direction="column" gap="$2">
          <Button hierarchy="primary" size="small" onClick={onClickButton}>
            {pt.activationRegister.dispatchStep.registerSuccess}
          </Button>

          <Button
            hierarchy="tertiary"
            size="small"
            onClick={() => steps.setList(machine.send.on.error)}
          >
            {pt.activationRegister.dispatchStep.registerFailed}
          </Button>
        </Flex>
      </DispatchStepContainer>
    </Flex>
  )
}

export { DispatchStep }
