interface Sector {
  sectorId: number
  name: string
  responsibility: 'RESPONSIBLE' | 'CORRESPONSIBLE'
}

interface SectorDTO {
  data: Sector[]
}

enum SectorType {
  All = 'all',
  Responsible = 'resp',
  Coresponsible = 'co-resp',
}

export { SectorType }
export type { Sector, SectorDTO }
