import React from 'react'

import { ColorThemeProps } from '@grupoboticario/flora'
import { Text } from '@grupoboticario/flora-react'
import { CheckCircleIcon, ClockIcon, CrossCircleIcon } from '@grupoboticario/flora-react-icons'

import { pt } from '@shared/locales'

import { StepStatus } from '../../../activation-register.types'
import {
  Arrow,
  DescriptionContainer,
  LineArrowContainer,
  LineDashed,
  StatusContainer,
  StepItemContainer,
} from './step-item.styles'

interface StepItemProps {
  description: string
  status: StepStatus
}

const StepItem: React.FC<StepItemProps> = ({ status, description }) => {
  const itemStatus = statuses[status]

  return (
    <StepItemContainer>
      <StatusContainer>
        {itemStatus.icon}

        <Text
          color={itemStatus.textColor as ColorThemeProps}
          size="bodyLargeStandardMedium"
          weight="medium"
        >
          {itemStatus.text}
        </Text>

        {(status === 'done' || status === 'error') && <LineArrow />}
        {(status === 'await' || status === 'current') && <LineDashed />}
      </StatusContainer>

      <DescriptionContainer>
        <Text size="bodySmallStandardMedium" color="$nonInteractiveAuxiliar">
          {description}
        </Text>
      </DescriptionContainer>
    </StepItemContainer>
  )
}

const LineArrow = () => (
  <LineArrowContainer>
    <Arrow />
  </LineArrowContainer>
)

const statuses = {
  done: {
    textColor: '$nonInteractiveEmphasis',
    text: pt.activationRegister.steps.done,
    icon: (
      <CheckCircleIcon
        size={24}
        color="$backgroundPrimary"
        css={{ backgroundColor: '$nonInteractiveEmphasis', borderRadius: '$pill' }}
      />
    ),
  },
  await: {
    textColor: '$nonInteractiveAuxiliar',
    text: pt.activationRegister.steps.await,
    icon: (
      <ClockIcon
        size={24}
        color="$backgroundPrimary"
        css={{ backgroundColor: '$nonInteractiveAltAuxiliar', borderRadius: '$pill' }}
      />
    ),
  },
  error: {
    textColor: '$nonInteractiveAuxiliar',
    text: pt.activationRegister.steps.error,
    icon: (
      <CrossCircleIcon
        size={24}
        color="$backgroundPrimary"
        css={{ backgroundColor: '$error-standard', borderRadius: '$pill' }}
      />
    ),
  },
  current: {
    textColor: '$actionableDefault',
    text: pt.activationRegister.steps.current,
    icon: (
      <ClockIcon
        size={24}
        color="$backgroundPrimary"
        css={{ backgroundColor: '$actionableDefault', borderRadius: '$pill' }}
      />
    ),
  },
}

export { StepItem }
