import { FloraButton as Button, Flex, Text } from '@grupoboticario/flora-react'

import { pt } from '@shared/locales'

import { Container } from './error-state.styles'

interface ErrorStateProps {
  description?: string
  height?: string
  icon?: React.ReactNode
  onButtonClick?: () => void
  title?: string
}

const ErrorState = ({
  onButtonClick,
  description,
  title,
  height,
  icon,
}: ErrorStateProps): JSX.Element => (
  <Container
    data-testid="error-state"
    align="center"
    justify="center"
    direction="column"
    css={{ height: height ?? 'auto' }}
  >
    <Flex direction="column" gap="$4" align="center" justify="center">
      {icon}

      <Flex direction="column" gap="$2" align="center" justify="center">
        <Text
          as="h5"
          size="bodyLargeShortMedium"
          color="$nonInteractivePredominant"
          weight="medium"
        >
          {title ?? pt.common.errorState.title}
        </Text>

        {description && (
          <Text size="bodySmallShortRegular" color="$nonInteractiveAuxiliar">
            {description}
          </Text>
        )}
      </Flex>

      {onButtonClick && (
        <Button hierarchy="secondary" onClick={onButtonClick}>
          {pt.common.errorState.buttonText}
        </Button>
      )}
    </Flex>
  </Container>
)

export { ErrorState, type ErrorStateProps }
