import { Flex, Spinner, Text } from '@grupoboticario/flora-react'
import { Container } from './loading-state.styles'

interface LoadingStateProps {
  isLoading?: boolean
  title?: string
  description?: string
  containerHeight?: string
  containerWidth?: string
}

const LoadingState = ({
  containerWidth,
  containerHeight,
  description,
  isLoading = true,
  title,
}: LoadingStateProps): JSX.Element | null => {
  if (isLoading) {
    return (
      <Container
        data-testid="loading"
        css={{
          width: containerWidth ?? '100%',
          height: containerHeight ?? 'auto',
        }}
      >
        <Flex direction="column" gap="$4" align="center" justify="center">
          <Flex direction="column" gap="$2" align="center" justify="center">
            {title && (
              <Text
                as="h5"
                size="bodyLargeShortMedium"
                color="$nonInteractivePredominant"
                weight="medium"
              >
                {title}
              </Text>
            )}

            {description && (
              <Text size="bodySmallShortRegular" color="$nonInteractiveAuxiliar">
                {description}
              </Text>
            )}
          </Flex>

          <Spinner size="md" color="$9" />
        </Flex>
      </Container>
    )
  }

  return null
}

export { LoadingState, type LoadingStateProps }
