import { Flex, styled } from '@grupoboticario/flora-react'

const ActionsContainer = styled(Flex, {
  backgroundColor: '$backgroundSecondary',
  borderRadius: '$medium',
  padding: '$4',
  alignItems: 'center',
  gap: '$4',
})

export { ActionsContainer }
