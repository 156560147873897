import { GtmTracker, IEventTracker } from '@vdi/event-tracker'

import { useMemo } from 'react'
import { createSegmentationEvents, type SegmentationEvents } from './events.segmentation'
import { Event } from './events.types'

type UseEvents = SegmentationEvents

const tracker = GtmTracker as unknown as IEventTracker<Event, string>

const useEvents = (): UseEvents => useMemo(() => createSegmentationEvents(tracker), [])

export { useEvents, type UseEvents }
