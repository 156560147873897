import { styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: '$4',
  gridRowGap: '$4',
  gap: '$4',
  paddingBottom: '$4',

  '& [data-flora="input-wrapper"]': {
    minHeight: 'unset',
  },

  '& [data-flora="multiple-select"]': {
    paddingTop: '$5 !important',
  },
})

const FormGroup = styled('div', {
  gridColumn: '1 / -1',

  variants: {
    gridType: {
      half: {
        '&:first-of-type': {
          '@desktop': {
            gridColumn: '1 / 2',
          },
        },

        '&:nth-child(2)': {
          '@desktop': {
            gridColumn: '2 / -1',
          },
        },
      },

      full: {},
    },
  },
})

export { Container, FormGroup }
