/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useContext,
  useEffect,
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from 'react'

import { type DataTableOrder, type DataTableProps, type RecordKey } from '../data-table.types'

interface TableProviderProps<T> extends DataTableProps<T> {
  children: ReactNode
}

const TableProvider = <T,>({ children, ...props }: TableProviderProps<T>): JSX.Element => {
  const [sort, setSort] = useState<HeaderSort<T>>({ key: '' as RecordKey<T>, order: 'none' })
  const [recordsChecked, setRecordsChecked] = useState<T[]>([])

  useEffect(() => {
    setRecordsChecked([])
  }, [props.records])

  return (
    <TableContext.Provider value={props}>
      <TableHeaderContext.Provider value={{ sort, setSort }}>
        <TableBodyContext.Provider value={{ recordsChecked, setRecordsChecked }}>
          {children}
        </TableBodyContext.Provider>
      </TableHeaderContext.Provider>
    </TableContext.Provider>
  )
}

interface TableHeaderContextProps<T> {
  sort: HeaderSort<T>

  setSort: Dispatch<SetStateAction<HeaderSort<T>>>
}

interface HeaderSort<T> {
  key: RecordKey<T>
  order: DataTableOrder
}

interface TableBodyContextProps<T> {
  recordsChecked: T[]
  setRecordsChecked: Dispatch<SetStateAction<T[]>>
}

const TableContext = createContext<DataTableProps<any> | null>(null)
const TableHeaderContext = createContext<TableHeaderContextProps<any> | null>(null)
const TableBodyContext = createContext<TableBodyContextProps<any> | null>(null)

const useTableContext = <T,>(): DataTableProps<T> => {
  const context = useContext(TableContext)
  if (!context) throw new Error('')
  return context
}

const useTableHeaderContext = <T,>(): TableHeaderContextProps<T> => {
  const context = useContext(TableHeaderContext)
  if (!context) throw new Error('')
  return context
}

const useTableBodyContext = <T,>(): TableBodyContextProps<T> => {
  const context = useContext(TableBodyContext)
  if (!context) throw new Error('')
  return context
}

export { TableProvider, useTableBodyContext, useTableContext, useTableHeaderContext }
