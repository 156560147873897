import { styled } from '@grupoboticario/flora-react'

const AvatarContainer = styled('span', {
  position: 'absolute',
  top: 'calc(50% - $12 / 2)',
  left: '$4',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$12',
  height: '$12',
  borderRadius: '50%',
  backgroundColor: '$nonInteractiveAltAuxiliar',
  color: '$nonInteractiveAltPredominant',
  fontSize: '$2subtitleMobile',
  fontWeight: '$medium',

  '@tablet': {
    left: '$8',
  },
})

export { AvatarContainer }
