import { styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridRowGap: '$5',
  paddingTop: '$9',
  paddingBottom: '$5',

  '@tablet': {
    gridTemplateColumns: '1fr 1fr 1fr',
    alignItems: 'center',
  },

  '> p': {
    textAlign: 'center',

    '@tablet': {
      textAlign: 'left',
    },
  },
})

const ItemsPerPageContainer = styled('div', {
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$3',

  '@tablet': {
    justifyContent: 'flex-end',
  },

  div: {
    width: '180px',
    minHeight: 'initial',
  },
})

export { Container, ItemsPerPageContainer }
