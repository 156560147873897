import React, { type ReactNode } from 'react'

import { Flex } from '@grupoboticario/flora-react'
import { ExclamationMarkHexagonIcon } from '@grupoboticario/flora-react-icons'

import { useSectorsContext } from '@sectors-filter/providers'
import { ErrorState } from '@shared/components'
import { pt } from '@shared/locales'

import { ListTable } from './modules'
import { ResellerListProvider } from './providers'

interface ResellerListProps {
  middle: ReactNode
  showTags?: boolean
}

const ResellerList: React.FC<ResellerListProps> = ({ middle, showTags }) => {
  const sectors = useSectorsContext()

  if (sectors.filled && !sectors.items.length) {
    return (
      <Flex direction="column" align="center" justify="center">
        <ErrorState
          title={pt.resellerList.withoutSectorsTitle}
          description={pt.resellerList.withoutSectorsDescription}
          icon={<ExclamationMarkHexagonIcon size="70px" color="$error-standard" />}
          height="calc(100vh - 50px)"
        />
      </Flex>
    )
  }

  return (
    <ResellerListProvider>
      <ListTable middle={middle} showTags={showTags} />
    </ResellerListProvider>
  )
}

export { ResellerList }
