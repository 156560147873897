import React from 'react'

import { useRegisterSteps } from '../../../activation-register/hooks'
import { DispatchStep } from './dispatch-step'
import { RegisterStep, RegisterStepError } from './register-step'
import {
  RegisterStepsContainer,
  StepComponentContainer,
  StepItemsContainer,
} from './register-steps.styles'
import { StepItem } from './step-item'

const RegisterSteps: React.FC = () => {
  const steps = useRegisterSteps()
  const StepComponent = components[steps.current.id]

  return (
    <RegisterStepsContainer>
      <StepItemsContainer>
        {steps.list.map((step) => (
          <StepItem key={step.id} {...step} />
        ))}
      </StepItemsContainer>

      <StepComponentContainer>
        <StepComponent />
      </StepComponentContainer>
    </RegisterStepsContainer>
  )
}

const components: Record<string, React.FC> = {
  dispatch: DispatchStep,
  register: RegisterStep,
  registerError: RegisterStepError,
}

export { RegisterSteps }
