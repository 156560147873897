import { MyLocationIcon } from '@grupoboticario/flora-react-icons'

import { pt } from '@shared/locales'

import { Container, Content, Description, Title } from './mission.styles'
import { MissionProps } from './mission.types'

const Mission = ({ description, show }: MissionProps): JSX.Element | null => {
  const { mission } = pt

  if (!show) {
    return null
  }

  return (
    <Container>
      <MyLocationIcon color="$nonInteractiveEmphasis" size="24px" aria-label={mission.ariaLabel} />

      <Content>
        <Title as="h4">{mission.title}</Title>
        <Description as="p">{description}</Description>
      </Content>
    </Container>
  )
}

export { Mission }
