import { styled } from '@grupoboticario/flora-react'

const StepItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
  flex: 1,
})

const StatusContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
})

const DescriptionContainer = styled('div', {
  paddingLeft: '$8',
})

const LineArrowContainer = styled('div', {
  position: 'relative',
  flex: 1,
})

const Arrow = styled('div', {
  width: '100%',
  height: '2px',
  backgroundColor: '$nonInteractiveAltAuxiliar',

  '&::after, &::before': {
    content: '',
    position: 'absolute',
    width: '10px',
    height: '2px',
    right: '-1.5px',
    backgroundColor: '$nonInteractiveAltAuxiliar',
  },

  '&::after': {
    top: '-3px',
    transform: 'rotate(45deg)',
  },

  '&::before': {
    top: '3px',
    transform: 'rotate(-45deg)',
  },
})

const LineDashed = styled('div', {
  height: '2px',
  flex: 1,
  background:
    'repeating-linear-gradient(90deg, transparent, transparent 5px, $nonInteractiveAltAuxiliar 5px, $nonInteractiveAltAuxiliar 10px)',
})

export {
  Arrow,
  DescriptionContainer,
  LineArrowContainer,
  LineDashed,
  StatusContainer,
  StepItemContainer,
}
