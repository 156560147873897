import { styled, Text } from '@grupoboticario/flora-react'

const StyledDescription = styled(Text, {
  color: '$nonInteractiveAuxiliar',
  fontSize: '0.6875rem',
  fontWeight: '$medium',
  letterSpacing: '0.08rem',
  lineHeight: '$short',
  textTransform: 'uppercase',
})

export { StyledDescription }
