import { styled } from '@grupoboticario/flora'

const Alert = styled('p', {
  display: 'inline-flex',
  alignItems: 'center',
  width: '100%',
  height: '$10',
  backgroundColor: '$statusInfoBackgroundDefault',
  borderRadius: '$radii$medium',
  color: '$statusInfoNonInteractiveEmphasis',
  fontSize: '$bodySmall',
  fontWeight: '$medium',
  gap: '$4',
  padding: '$2 $4',
})

export { Alert }
