import { styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  paddingRight: '2px',

  '& *:first-child': {
    flex: 1,
  },
})

export { Container }
