import { styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '$4',

  '& button': {
    width: '100%',
  },

  '@tablet': {
    '& button': {
      width: 'auto',
    },
  },
})

const TagsContainer = styled('div', {
  display: 'none',

  '@tablet': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '$2',
  },
})

const TagList = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$2',
})

const IconContainer = styled('div', {
  display: 'flex',
})

export { TagsContainer, IconContainer, TagList, Container }
