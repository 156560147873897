import { Flex, styled, Text } from '@grupoboticario/flora-react'
import { ShoppingBagIcon as ShoppingBagIconFlora } from '@grupoboticario/flora-react-icons'

const ProductDescription = styled(Text, {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '$3',
  fontSize: '$bodySmall',
  fontWeight: '$medium',
  lineHeight: '$bodyLarge',
  marginBottom: 0,
})

const ShoppingBagIcon = styled(ShoppingBagIconFlora, {
  width: '$4',
  height: '$4',
  color: '$actionableDefault',
})

const ContainerText = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'initial',
  gap: '$2',
  width: '100%',
  paddingLeft: '$2',

  '> p': {
    width: '100%',
    margin: 0,
  },
})

export { ProductDescription, ShoppingBagIcon, ContainerText }
