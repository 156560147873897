import {
  Button,
  ModalCloseButton as FloraModalCloseButton,
  ModalFooter as FloraModalFooter,
  styled,
} from '@grupoboticario/flora-react'

const InformationContainer = styled('div', {
  paddingBottom: '$5',
})

const ModalCloseButton = styled(FloraModalCloseButton, {
  top: '$4',
})

const ModalFooter = styled(FloraModalFooter, {
  borderTop: '1px solid $nonInteractiveAltAuxiliar',
  padding: '$2 0 $5',
  margin: '0 $5',
})

const StyledButton = styled(Button, {
  position: 'absolute',
  right: '$2',
  top: 'calc(50% - $8 / 2)',

  '@tablet': {
    right: '$4',
  },
})

export { InformationContainer, ModalCloseButton, ModalFooter, StyledButton }
