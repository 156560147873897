import React, { Fragment } from 'react'

import { Button, Drawer, DrawerCloseButton, Text } from '@grupoboticario/flora-react'

import { useEvents } from '@shared/events'
import { DrawerProps } from '@shared/providers'

import {
  StyledDrawerBody,
  StyledDrawerContent,
  StyledDrawerFooter,
  StyledDrawerHeader,
} from './generic-drawer.styles'

interface GenericDrawerProps {
  drawer: DrawerProps | null
  onOpenChange: () => void
}

const GenericDrawer: React.FC<GenericDrawerProps> = ({ drawer, onOpenChange }) => {
  const events = useEvents()
  const isOpen = drawer !== null
  const Wrapper = drawer?.wrapper ?? Fragment

  const onClickButton = (): void => {
    events.clickStartAttendance()
    drawer?.header.button?.onClick()
  }

  return (
    <Drawer data-testid="drawer-provider:component" open={isOpen} onOpenChange={onOpenChange}>
      <Wrapper>
        <StyledDrawerContent css={{ background: drawer?.background }}>
          <DrawerCloseButton />

          <StyledDrawerHeader>
            <Text as="h4" size="subTitleDeskRegular" weight="regular">
              {drawer?.header.title}
              <Text
                as="small"
                size="exceptionsAuxiliarMedium"
                color="$nonInteractiveAuxiliar"
                weight="medium"
                css={{ display: 'block' }}
              >
                {drawer?.header.subtitle}
              </Text>
            </Text>

            {drawer?.header.button && (
              <Button size="small" onClick={onClickButton}>
                {drawer.header.button.label}
              </Button>
            )}
          </StyledDrawerHeader>

          <StyledDrawerBody>{drawer?.body.node}</StyledDrawerBody>

          {drawer?.footer && <StyledDrawerFooter>{drawer.footer.node}</StyledDrawerFooter>}
        </StyledDrawerContent>
      </Wrapper>
    </Drawer>
  )
}

export { GenericDrawer }
