import { useStorage } from '@shared/hooks'
import { useFiltersContext } from '@shared/providers'
import { useSectorsSelectedContext } from '..'

interface UseSectorsManagement {
  sectorsSelected: number[]
  disabledEdition: boolean
  confirmChange: () => void
  changeSectors: (selecteds: number[]) => void
}

const useSectorsManagement = (): UseSectorsManagement => {
  const sectorsStg = useStorage<number[]>('segmentation:sectors')
  const { sectorsSelected, setSectorsSelected } = useSectorsSelectedContext()
  const filters = useFiltersContext()

  const disabledEdition = sectorsSelected.length <= 0

  const confirmChange = (): void => {
    filters.changeFilters({ sectorId: sectorsSelected })
    sectorsStg.set(sectorsSelected)
  }

  const changeSectors = (selecteds: number[]): void => {
    setSectorsSelected(selecteds)
  }

  return {
    sectorsSelected,
    disabledEdition,
    confirmChange,
    changeSectors,
  }
}

export { useSectorsManagement }
