import React from 'react'

import { useApiData } from '@shared/api'
import {
  useFiltersContext,
  type FilterFieldDropdownAsync,
  type FilterFieldOption,
} from '@shared/providers'

import { type ObjectivesFormHandler } from '../../objectives-filter.types'
import { DropdownField } from './dropdown-field'

type DropdownFieldProps = FilterFieldDropdownAsync & ObjectivesFormHandler<string | string[]>

const DropdownFieldAsync: React.FC<DropdownFieldProps> = ({ dataSource, disabled, ...props }) => {
  const filters = useFiltersContext()

  const { data, loading } = useApiData<{ data: FilterFieldOption[] }>({
    params: { sectorId: filters.current.sectorId },
    dataSource,
  })

  return (
    <DropdownField
      {...props}
      type="dropdown"
      options={data?.data ?? []}
      disabled={loading || disabled}
    />
  )
}

export { DropdownFieldAsync }
