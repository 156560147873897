import { Flex, styled } from '@grupoboticario/flora-react'

const ContainerText = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'initial',
  gap: '$2',
  width: '100%',
  paddingLeft: '$2',

  '> p': {
    width: '100%',
    margin: 0,
  },
})

export { ContainerText }
