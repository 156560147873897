import { styled } from '@grupoboticario/flora-react'

const Form = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  width: 'auto',
  overflow: 'hidden',

  'ul, li': {
    listStyle: 'none',
    padding: '0 !important',
    margin: '0 !important',
  },

  'ul > li:first-child > button': {
    borderTopColor: 'transparent !important',
  },

  '@tablet': {
    width: '500px',
  },
})

const Divider = styled('div', {
  height: '1px',
  background: '$nonInteractiveAltAuxiliar',
})

const FilterActionContainer = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'stretch',
  gap: '$4',

  button: {
    flex: 1,
  },
})

export { Form, Divider, FilterActionContainer }
