import { type ChangeEvent } from 'react'

import { FloraInput as Input } from '@grupoboticario/flora-react'

import { type ObjectivesFormHandler } from '@objectives-filter/objectives-filter.types'
import { type FilterFieldInput } from '@shared/providers'

type InputFieldProps = FilterFieldInput & ObjectivesFormHandler<number | null>

const InputField = ({
  onChange,
  disabled,
  filters,
  label,
  form,
  style,
}: InputFieldProps): JSX.Element => {
  const [filter] = filters
  let value = form[filter.name] ?? ''

  if (style === 'currency') {
    value = Math.round((value as number) * 100)
  }

  const handleChange = (ev: ChangeEvent<HTMLInputElement>): void => {
    const cleanValue = ev.target.value.replace('R$', '').replace(/\./g, '').replace(',', '.')
    const floatValue = parseFloat(cleanValue)
    const inputValue = floatValue > 0 ? floatValue : null
    onChange({ [filter.name]: inputValue })
  }

  return (
    <Input
      id={`filter-field-${filter.name}`}
      label={label}
      value={value}
      disabled={disabled}
      onChange={handleChange}
      mask={{ style }}
    />
  )
}

export { InputField }
