import React from 'react'

import { RegisterModal, RegisterSteps } from './modules'
import { RegisterProvider } from './providers'

const ActivationRegister: React.FC = () => {
  return (
    <RegisterProvider>
      <RegisterModal>
        <RegisterSteps />
      </RegisterModal>
    </RegisterProvider>
  )
}

export { ActivationRegister }
