import { navigateToUrl } from 'single-spa'

import { toLocaleDate, toLocaleTime } from '@grupoboticario/vdi-mfe-utils'
import { type Reseller } from '@reseller-list/reseller-list.types'
import { type DrawerFactory } from '@shared/providers'

import { pt } from '@shared/locales'
import { ResellerDetail } from '../modules'

const resellerDetailDrawer: DrawerFactory<Reseller & { updatedAt: string }> = (_, reseller) => ({
  background: '$backgroundSecondary',
  header: {
    title: pt.resellerList.detailTitle,
    subtitle: pt.listTable.subtitle(
      toLocaleDate(reseller.updatedAt),
      toLocaleTime(reseller.updatedAt),
    ),
    button: {
      label: pt.resellerList.detailOpenAttendance,
      onClick: () => {
        const resellerId = encodeURIComponent(reseller.id)
        const path = `/ativacao/atendimento?resellerId=${resellerId}`
        navigateToUrl(path)
      },
    },
  },
  body: {
    node: <ResellerDetail reseller={reseller} />,
  },
})

export { resellerDetailDrawer }
