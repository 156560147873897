import { useEffect } from 'react'

import { DatePicker } from '@shared/components'
import { type FilterFieldDate } from '@shared/providers'

import { type ObjectivesFormHandler } from '../../objectives-filter.types'

type DateRangeFieldsProps = FilterFieldDate & ObjectivesFormHandler<string>

const DateRangeField: React.FC<DateRangeFieldsProps> = ({
  onChange,
  disabled,
  filters,
  maxDays,
  form,
  label,
}) => {
  const [start, end] = filters
  const now = new Date().getTime()
  const minPossibleDate = maxDays ? new Date(now - maxDays) : null
  const maxPossibleDate = maxDays ? new Date(now + maxDays) : null
  const startValue = form[start.name]
  const endValue = form[end.name]

  const handleChange = (dates: [Date | null, Date | null]): void => {
    const [startDate, endDate] = dates

    onChange({
      [start.name]: startDate?.toISOString() ?? '',
      [end.name]: endDate?.toISOString() ?? '',
    })
  }

  useEffect(() => {
    if (disabled) onChange({ [end.name]: '', [start.name]: '' })
  }, [disabled, end.name, onChange, start.name])

  return (
    <DatePicker
      selectsRange
      minDate={minPossibleDate}
      maxDate={maxPossibleDate}
      shouldCloseOnSelect={false}
      onChange={handleChange}
      labelText={label}
      disabled={disabled}
      startDate={startValue ? new Date(startValue) : null}
      endDate={endValue ? new Date(endValue) : null}
    />
  )
}

export { DateRangeField }
