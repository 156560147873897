import {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
} from 'react'

import { MetaState } from '@shared/api'

import { type ResellerList } from '../reseller-list.types'

const ResellerListProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [list, setList] = useState(initialState.list)

  return <ListContext.Provider value={{ list, setList }}>{children}</ListContext.Provider>
}

interface ListContextProps {
  list: ResellerList
  setList: Dispatch<SetStateAction<ListContextProps['list']>>
}

const initialState: ListContextProps = {
  list: {
    status: MetaState.Idle,
    resellers: [],
    updatedAt: '',
    isExtendedOrdering: false,
    extendedOrderingPeriod: {
      start: '',
      end: '',
    },
  },
  setList: () => {},
}

const ListContext = createContext(initialState)

const useListContext = (): ListContextProps => {
  const context = useContext(ListContext)
  return context
}

export { ListContext, ResellerListProvider, useListContext }
