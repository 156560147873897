import { GenericDrawer } from '@shared/components/generic-drawer'
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
  type PropsWithChildren,
  type ReactNode,
} from 'react'

const DrawerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [drawer, setDrawer] = useState<DrawerProps | null>(null)
  const registers = useRef(new Map<string, DrawerFactory>())

  function open<T = unknown>(drawerName: string, props?: T): void {
    const factory = registers.current.get(drawerName)
    if (factory) setDrawer(factory(context, props))
  }

  function close(): void {
    setDrawer(null)
  }

  const register = useCallback((drawerName: string, factory: DrawerFactory): void => {
    registers.current.set(drawerName, factory)
  }, [])

  const context = { open, close, register }

  return (
    <DrawerContext.Provider value={context}>
      {children}
      <GenericDrawer drawer={drawer} onOpenChange={context.close} />
    </DrawerContext.Provider>
  )
}

interface DrawerContextProps {
  register: (drawerName: string, factory: DrawerFactory) => void
  open: <T>(drawerName: string, props?: T) => void
  close: () => void
}

type DrawerFactory<T = unknown> = (context: DrawerContextProps, props: T) => DrawerProps

interface DrawerProps {
  background?: string
  wrapper?: React.FC
  header: {
    title: string
    subtitle?: string
    button?: {
      label: string
      onClick: () => void
    }
  }
  body: {
    node: ReactNode
  }
  footer?: {
    node: ReactNode
  }
}

const DrawerContext = createContext<DrawerContextProps>({
  register() {},
  close() {},
  open() {},
})

const useDrawerContext = (): DrawerContextProps => {
  return useContext(DrawerContext)
}

export { DrawerProvider, useDrawerContext, type DrawerFactory, type DrawerProps }
