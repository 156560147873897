import { useEffect, useRef, useState } from 'react'

import { useFiltersContext } from '@shared/providers'

import { SectorType } from '../sectors-filter.types'
import { usePortfolio, type UsePortfolio } from './use-portfolio'

interface UseTypeSectorsFilter {
  filterBySectorType: (value: SectorType) => void
  sectorType: SectorType
  portfolio: ReturnType<UsePortfolio>
}

const useTypeSectorsFilter = (): UseTypeSectorsFilter => {
  const [sectorType, setSectorType] = useState(SectorType.All)
  const { changeFilters } = useFiltersContext()
  const portfolio = usePortfolio()
  const changedManuallyRef = useRef(false)

  const getSectorsIdsByType = (type: SectorType): number[] => {
    const sectorId: number[] = []
    const responsibleIds = portfolio.responsible.map((s) => s.sectorId)
    const coresponsibleIds = portfolio.coresponsible.map((s) => s.sectorId)

    switch (type) {
      case SectorType.Responsible:
        sectorId.push(...responsibleIds)
        break
      case SectorType.Coresponsible:
        sectorId.push(...coresponsibleIds)
        break
      default:
        sectorId.push(...responsibleIds, ...coresponsibleIds)
    }

    return sectorId
  }

  useEffect(() => {
    if (changedManuallyRef.current) {
      changedManuallyRef.current = false
      return
    }

    if (portfolio.hasResponsible && portfolio.hasCoresponsible) {
      setSectorType(SectorType.All)
    }

    if (portfolio.hasResponsible && !portfolio.hasCoresponsible) {
      setSectorType(SectorType.Responsible)
    }

    if (!portfolio.hasResponsible && portfolio.hasCoresponsible) {
      setSectorType(SectorType.Coresponsible)
    }
  }, [setSectorType, portfolio])

  const filterBySectorType = (type: SectorType): void => {
    setSectorType(type)
    changedManuallyRef.current = true
    const sectorId = getSectorsIdsByType(type)
    changeFilters({ sectorId })
  }

  return {
    filterBySectorType,
    sectorType,
    portfolio,
  }
}

export { useTypeSectorsFilter }
