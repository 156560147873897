import { memo, useId } from 'react'

import { FloraButton as Button, FloraCheckbox as Checkbox } from '@grupoboticario/flora-react'
import { ChevronRightIcon } from '@grupoboticario/flora-react-icons'
import { Table } from '@grupoboticario/flora-team-react-table'
import { toCurrency, toPhoneNumber } from '@grupoboticario/vdi-mfe-utils'

import { StatusLabel } from '@shared/components'
import { useEvents } from '@shared/events'
import { pt } from '@shared/locales'

import {
  DataTableCurrencyColumn,
  type DataTableBtnColumn,
  type DataTablePhoneColumn,
  type DataTableStatusColumn,
  type DataTableTextColumn,
} from '../../data-table.types'

import { StyledTableCell, StyledText, TableCellContainer } from './body-cell.styles'

interface BodyCellTextProps<T> extends BodyCellBaseProps<T> {
  column: DataTableTextColumn<T>
  checked: boolean
}

interface BodyCellBaseProps<T> {
  record: T
}

const BodyCellText = memo(<T,>({ record, column, checked }: BodyCellTextProps<T>): JSX.Element => {
  const property = record[column.key] || '---'

  return (
    <StyledTableCell asChild align="left" checked={checked && column.copyable}>
      <TableCellContainer>
        <StyledText
          isTruncated
          size="bodyMediumShort"
          color="$nonInteractivePredominant"
          weight="medium"
        >
          {property}
        </StyledText>
      </TableCellContainer>
    </StyledTableCell>
  )
})

BodyCellText.displayName = 'BodyCellText'

interface BodyCellStatusProps<T> extends BodyCellBaseProps<T> {
  column: DataTableStatusColumn<T>
}

const BodyCellStatus = memo(<T,>({ record, column }: BodyCellStatusProps<T>): JSX.Element => {
  const propertie = record[column.key]
  const props = column.getStatusProps(propertie)

  return (
    <StyledTableCell asChild align="left">
      <StatusLabel
        isTruncated
        size="bodyMediumStandard"
        status={props.status}
        color="$nonInteractivePredominant"
        weight="medium"
      >
        {props.value}
      </StatusLabel>
    </StyledTableCell>
  )
})

BodyCellStatus.displayName = 'BodyCellStatus'

interface BodyCellBtnProps<T> extends BodyCellBaseProps<T> {
  record: T
  column: DataTableBtnColumn<T>
}

const BodyCellBtn = memo(<T,>({ record, column }: BodyCellBtnProps<T>): JSX.Element => {
  const events = useEvents()

  const onButtonClick = () => {
    events.clickAdditionalInformation()
    column.onBtnClick(record, column)
  }

  return (
    <Table.Cell>
      <Button
        size="small"
        aria-label={column.label}
        hierarchy="secondary"
        has="iconOnly"
        onClick={onButtonClick}
        icon={<ChevronRightIcon size={16} color="$actionableDefault" />}
      />
    </Table.Cell>
  )
})

BodyCellBtn.displayName = 'BodyCellBtn'

interface BodyCellCheckboxProps {
  onCheck: () => void
  checked: boolean
}

const BodyCellCheckbox = ({ onCheck, checked }: BodyCellCheckboxProps): JSX.Element => {
  const id = useId()

  return (
    <StyledTableCell asChild align="left" checked={checked}>
      <Checkbox
        id={id}
        onChange={onCheck}
        checked={checked}
        aria-label={pt.dataTable.checkboxRecordLabel}
      />
    </StyledTableCell>
  )
}

interface BodyCellCurrentProps<T> extends BodyCellBaseProps<T> {
  column: DataTableCurrencyColumn<T>
  checked: boolean
}

const BodyCurrencyText = memo(
  <T,>({ record, column, checked }: BodyCellCurrentProps<T>): JSX.Element => {
    const property = record[column.key]

    return (
      <StyledTableCell asChild align="left" checked={checked && column.copyable}>
        <TableCellContainer>
          <StyledText
            isTruncated
            size="bodyMediumShort"
            color="$nonInteractivePredominant"
            weight="medium"
          >
            {property ? toCurrency(property as number) : '---'}
          </StyledText>
        </TableCellContainer>
      </StyledTableCell>
    )
  },
)

BodyCurrencyText.displayName = 'BodyCurrencyText'

interface BodyPhoneCurrentProps<T> extends BodyCellBaseProps<T> {
  column: DataTablePhoneColumn<T>
  checked: boolean
}

const BodyPhoneText = memo(
  <T,>({ record, column, checked }: BodyPhoneCurrentProps<T>): JSX.Element => {
    const property = record[column.key]

    return (
      <StyledTableCell asChild align="left" checked={checked && column.copyable}>
        <TableCellContainer>
          <StyledText
            isTruncated
            size="bodyMediumShort"
            color="$nonInteractivePredominant"
            weight="medium"
          >
            {property ? toPhoneNumber(property as string) : '---'}
          </StyledText>
        </TableCellContainer>
      </StyledTableCell>
    )
  },
)

BodyPhoneText.displayName = 'BodyPhoneText'

export {
  BodyCellBtn,
  BodyCellCheckbox,
  BodyCellStatus,
  BodyCellText,
  BodyCurrencyText,
  BodyPhoneText,
}
