import { type IEventTracker } from '@vdi/event-tracker'
import {
  ActionType,
  CategoryType,
  InteractionDetailType,
  LabelType,
  type Event,
} from './events.types'

interface SegmentationEvents {
  clickTab: (tab: string) => void
  clickStartAttendance: () => void
  clickAdditionalInformation: () => void
  clickCopyRecords: () => void
  clickFilter: () => void
  clickApplyFilters: (filters: Record<string, unknown>) => void
  clickCheckboxCopy: (type: 'lote' | 'individual') => void
  clickCopyData: (type: 'lote' | 'individual') => void
  clickCopyRecordsOpenModal: () => void
  clickRegisterTrigger: () => void
  clickRegisterReason: (reason: string) => void
  clickRegister: (type: 'observation' | 'click', observation?: string) => void
  clickRegisterBack: () => void
  clickCopyLinkMLD: () => void
}

const createSegmentationEvents = (tracker: IEventTracker<Event, string>): SegmentationEvents => {
  const trackGa4 = (parameters: Record<string, string>) => {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: 'interaction_ativacao',
        ...parameters,
      },
    })
  }

  return {
    clickTab: function (tab: string) {
      tracker.track({
        event: 'event',
        action: ActionType.clickButton,
        category: CategoryType.activation,
        label: `${LabelType.linting}${tab}`,
      })
    },
    clickCopyRecords() {
      trackGa4({
        cd_interaction_detail: `${InteractionDetailType.click}:copiar-itens-selecionados`,
      })
    },
    clickStartAttendance() {
      trackGa4({
        cd_interaction_detail: `${InteractionDetailType.click}:inf-complementares-abrir-atendimento`,
      })
    },
    clickAdditionalInformation() {
      trackGa4({
        cd_interaction_detail: `${InteractionDetailType.click}:informacoes-complementares`,
      })
    },
    clickFilter() {
      trackGa4({ cd_interaction_detail: `${InteractionDetailType.click}:filtro` })
    },
    clickApplyFilters(filters: Record<string, unknown>) {
      const filterEvents: string[] = []

      Object.entries(filters).forEach(([key, value]) => {
        const filterEvStr = filterEventsMap[key]
        const isInvalid = !value && typeof value !== 'boolean'
        const isIncludedValue = filterEvents.includes(filterEvStr)

        if (isIncludedValue || isInvalid || !filterEvStr) return
        filterEvents.push(filterEvStr)
      })

      trackGa4({
        cd_interaction_detail: `${InteractionDetailType.click}:aplicar-filtros`,
        cd_filtros: filterEvents.join(','),
      })
    },
    clickCheckboxCopy(type) {
      trackGa4({
        cd_interaction_detail: `${InteractionDetailType.checkbox}:${type}`,
      })
    },
    clickCopyData(type) {
      trackGa4({
        cd_interaction_detail: `copiar_res_${type}`,
      })
    },
    clickCopyRecordsOpenModal() {
      trackGa4({
        cd_interaction_detail: `${InteractionDetailType.interactionModal}:envio-dos-acionamentos`,
      })
    },
    clickRegisterTrigger() {
      trackGa4({
        cd_interaction_detail: `${InteractionDetailType.click}:registrar-acionamento`,
      })
    },
    clickRegisterReason(reason: string) {
      const transformToSlug = (reason: string): string => {
        const noParentheses = reason.replace(/\(.+\)/, '')
        const hyphenated = noParentheses.replace(/\s+/g, '-')
        const trimmed = hyphenated.replace(/-$/, '')

        return trimmed.toLowerCase()
      }

      const slug = transformToSlug(reason)

      trackGa4({
        cd_interaction_detail: `${InteractionDetailType.reasonTrigger}:${slug}`,
      })
    },
    clickRegister(type, observation) {
      trackGa4({
        cd_interaction_detail: `${InteractionDetailType[type]}:${
          type === 'click' ? 'registar_acionamento' : observation
        }`,
      })
    },
    clickRegisterBack() {
      trackGa4({
        cd_interaction_detail: `${InteractionDetailType.popupRegisterTrigger}:${InteractionDetailType.click}:voltar`,
      })
    },
    clickCopyLinkMLD() {
      trackGa4({
        cd_interaction_detail: `${InteractionDetailType.click}:copiar_mld`,
      })
    },
  }
}

const filterEventsMap: Record<string, string> = {
  activatedERCycle: 'compER',
  appAdhesion: 'adeApp',
  blocked: 'bloq',
  birthEndDate: 'niver',
  birthInitDate: 'niver',
  brandsBought: 'm6ci',
  city: 'cid',
  creditLimit: 'credMin',
  cs: 'cs',
  currentCycle: 'ciAtual',
  cycle: 'ina',
  harvest: 'safra',
  increaseCycleLimit: 'maj',
  isMultiBrandActive: 'compMU',
  mission: 'missao',
  negotiationTitle: 'reneg',
  nextRangeValue: 'vFid',
  promotion: 'promo',
  roles: 'fid',
  tabulationEnd: 'ultAtend',
  tabulationInit: 'ultAtend',
  useCycleRange: 'semAtendCi',
  zoneer: 'zona',
}

export { createSegmentationEvents, type SegmentationEvents }
