import { Button as FB, styled } from '@grupoboticario/flora-react'

const Button = styled(FB, {
  width: '$6 !important',
  height: '$6 !important',
  borderWidth: 0,
  padding: '0 !important',
  cursor: 'pointer',
  outlineWidth: '2px',
  outlineStyle: 'dashed',
  outlineColor: 'transparent',
  transitionProperty: 'opacity, outline',
  transitionTimingFunction: 'ease-out',
  transitionDuration: '0.2s',

  '&:hover': {
    opacity: 0.8,
  },

  '&:focus-visible': {
    outlineColor: '$actionableDefault',
    outlineOffset: '1px',
    boxShadow: 'none',
  },
})

const Feedback = styled('span', {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '$success-standard',
  borderRadius: '$medium',
  gap: '$2',
  textWrap: 'nowrap',
  padding: '$4',
  zIndex: '$popover',
})

export { Button, Feedback }
