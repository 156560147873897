import React, {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type PropsWithChildren,
} from 'react'

import { type FilterValues } from '@shared/providers'

interface ObjectiveContextProps {
  form: FilterValues
  setForm: Dispatch<React.SetStateAction<FilterValues>>
}

const ObjectivesFormProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [form, setForm] = useState<FilterValues>({})

  return (
    <ObjectivesFormContext.Provider value={{ form, setForm }}>
      {children}
    </ObjectivesFormContext.Provider>
  )
}

const ObjectivesFormContext = createContext<ObjectiveContextProps | null>(null)

const useObjectiveFormContext = (): ObjectiveContextProps => {
  const context = useContext(ObjectivesFormContext)
  if (!context) throw new Error('ObjectivesFormContext is not available')
  return context
}

export { ObjectivesFormProvider, useObjectiveFormContext }
