import { styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  position: 'relative',
  width: '100%',
  height: '$14',
  backgroundColor: '$backgroundPrimary',
  borderColor: '$nonInteractiveOutline',
  borderRadius: '$radii$medium',
  borderStyle: 'solid',
  borderWidth: '1px',
  padding: '$2 145px $2 65px',
  margin: '0 auto',

  '@tablet': {
    padding: '$2 160px $2 80px',
  },
})

const EndContainer = styled('div', {
  marginLeft: 'auto',
})

export { Container, EndContainer }
