import { styled } from '@grupoboticario/flora-react'

const DispatchStepContainer = styled('div', {
  display: 'flex',
  gap: '$4',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 250,
})

export { DispatchStepContainer }
