import { useMemo } from 'react'

interface UseStorage<T> {
  get: () => T | null
  set: (value: T) => void
  clean: () => void
}

const useStorage = <T>(key: string): UseStorage<T> => {
  return useMemo(() => {
    const set = (value: T): void => {
      const item = JSON.stringify(value)
      localStorage.setItem(key, item)
    }

    const get = (): T | null => {
      let item = localStorage.getItem(key)
      if (item) item = JSON.parse(item)
      return item as unknown as T | null
    }

    const clean = (): void => {
      localStorage.setItem(key, '')
    }

    return { get, set, clean }
  }, [key])
}

export { useStorage }
