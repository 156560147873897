export const env = Object.freeze({
  API_BASE_URL: process.env.API_BASE_URL,
  CONFIGCAT_SDK_KEY: process.env.CONFIGCAT_SDK_KEY,
  API_TIMEOUT: process.env.API_TIMEOUT,
} as unknown as Env)

interface Env {
  API_BASE_URL: string
  CONFIGCAT_SDK_KEY: string
  API_TIMEOUT: string
}
