const isEmpty = (obj: object): boolean => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key as keyof object] !== undefined) {
      return false
    }
  }

  return true
}

export { isEmpty }
