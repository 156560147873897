import React from 'react'

import { Switch, Text } from '@grupoboticario/flora-react'

import { type FilterFieldSwitch } from '@shared/providers'

import { type ObjectivesFormHandler } from '../../objectives-filter.types'
import { Container } from './switch-field.styles'

type SwitchFieldProps = FilterFieldSwitch & ObjectivesFormHandler<string | boolean>

const SwitchField: React.FC<SwitchFieldProps> = ({ filters, form, label, onChange }) => {
  const [filter] = filters
  const value = form[filter.name]
  const id = `filter-field-${filter.name}`

  const handleChange = (checked: boolean): void => {
    onChange({ [filter.name]: checked ? 'true' : '' })
  }

  return (
    <Container>
      <Text as="label" htmlFor={id} size="bodyMediumShort">
        {label}
      </Text>
      <Switch id={id} onCheckedChange={handleChange} checked={value === 'true'} />
    </Container>
  )
}

export { SwitchField }
