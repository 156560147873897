import { Fragment } from 'react'

import { Flex, Text } from '@grupoboticario/flora-react'
import { CircleDashIcon, TagOutlineIcon } from '@grupoboticario/flora-react-icons'

import { AccordionItem } from '@shared/components'
import { pt } from '@shared/locales'

import { ContainerText } from './promotion-item.styles'

interface PromotionItemProps {
  name: string
  promotions: string[]
}

const PromotionItem = ({ name, promotions }: PromotionItemProps): JSX.Element => {
  const { promotions: promotionItem } = pt
  const campaignNames: Record<string, string> = promotionItem.campaigns

  return (
    <AccordionItem
      title={campaignNames[name]}
      content={
        <Flex direction="row" align="center" justify="space-between" wrap="wrap" gap="$6">
          {promotions.length > 0 && (
            <Fragment>
              {promotions.map((promotion, index) => (
                <ContainerText key={index}>
                  <TagOutlineIcon color="$actionableAltContent" size={16} />
                  <Text as="p" size="bodySmallShortMedium" weight="medium">
                    {promotion}
                  </Text>
                </ContainerText>
              ))}
            </Fragment>
          )}

          {!promotions.length && (
            <ContainerText>
              <CircleDashIcon color="$alert-standard" size={16} />
              <Text as="p" size="bodyMediumShort" color="$alert-standard" weight="medium">
                {promotionItem.noExclusivePromotionsAvailable}
              </Text>
            </ContainerText>
          )}
        </Flex>
      }
    />
  )
}

export { PromotionItem, type PromotionItemProps }
