import { styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',

  '& h3': {
    margin: 0,
  },
})

const ContainerTags = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  width: '100%',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  scrollbarWidth: 'none',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const Title = styled('h3', {
  position: 'absolute',
  top: 'calc(50% - $5 / 2)',
  left: '$2',
  color: '$nonInteractivePredominant',
  fontSize: '$bodySmall',
  fontWeight: '$regular',
  lineHeight: '$short',

  '@tablet': {
    left: '$4',
  },
})

export { Container, ContainerTags, Title }
