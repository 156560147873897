import { useId } from 'react'

import { FloraButton as Button, Flex, FloraTextarea as Textarea } from '@grupoboticario/flora-react'

import { pt } from '@shared/locales'

import { useApiData } from '@shared/api'
import { LoadingState } from '@shared/components'
import { Reason } from 'src/activation-register/activation-register.types'
import { useRegister } from '../../../hooks'

const RegisterStep: React.FC = () => {
  const register = useRegister()

  const { data, loading } = useApiData<Reason[]>({
    dataSource: 'getReasonsActivationRecord',
    params: { showInActivationRecords: true },
  })

  const onClickButton = (reason: Reason) => (): undefined => {
    register.dispatch({
      type: 'change-reason',
      payload: {
        success: true,
        text: reason.reason,
        id: reason.id,
      },
    })
  }

  if (loading) {
    return <LoadingState isLoading={loading} containerHeight="140px" />
  }

  return (
    <Flex direction="column" gap="$1" align="flex-start">
      {data?.map((reason, index) => (
        <Button
          key={index + reason.reason}
          size="small"
          hierarchy={register.text === reason.reason ? 'primary' : 'secondary'}
          onClick={onClickButton(reason)}
          disabled={register.loading}
        >
          {reason.reason}
        </Button>
      ))}
    </Flex>
  )
}

const RegisterStepError: React.FC = () => {
  const fieldId = useId()
  const register = useRegister()

  return (
    <Flex direction="column" gap="$1">
      <Textarea
        label={pt.activationRegister.registerStepError.fieldLabel}
        labelPosition="external"
        id={fieldId}
        disabled={register.loading}
        minLength={15}
        maxLength={300}
        value={register.text || ''}
        status={register.isValid ? undefined : 'error'}
        helperText={register.isValid ? '' : pt.activationRegister.registerStepError.minChar}
        placeholder={pt.activationRegister.registerStepError.fieldPlaceholder}
        onChange={(e) =>
          register.dispatch({
            type: 'change-reason',
            payload: { text: e.target.value, id: undefined, success: false },
          })
        }
      />
    </Flex>
  )
}

export { RegisterStep, RegisterStepError }
