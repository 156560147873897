import { AccordionButton, Flex, styled, Text } from '@grupoboticario/flora-react'

const Header = styled(AccordionButton, {
  width: '100%',

  '> span': {
    '&:first-of-type': {
      width: 'inherit',
    },
  },
})

const HeaderContainer = styled(Flex, {
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  flexWrap: 'wrap',
})

const StyledAccordionContent = styled('div', {
  backgroundColor: '$backgroundSecondary',
  padding: '$6 $4',
})

const Title = styled(Text, {
  minWidth: '120px',
  marginBottom: 0,
})

export { Header, HeaderContainer, StyledAccordionContent, Title }
