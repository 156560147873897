import React from 'react'

import { Tag, Text } from '@grupoboticario/flora-react'
import { CrossIcon } from '@grupoboticario/flora-react-icons'

import { pt } from '@shared/locales'

import { useTableContext } from '../table-provider'
import { Container, IconContainer, TagList, TagsContainer } from './filters.styles'

const Filters: React.FC = () => {
  const { filter } = useTableContext()

  if (!filter) return null

  return (
    <Container>
      <TagsContainer>
        <Text size="exceptionsAuxiliarRegular">{pt.dataTable.appliedFilters}</Text>

        <TagList>
          {filter.tags.map((tag, index) => (
            <Tag
              key={index}
              shape="ghost"
              size="small"
              rightIcon={
                <IconContainer
                  role="button"
                  aria-label={pt.dataTable.removeFilter(tag.label, tag.value)}
                  onClick={() => filter.onFilterRemove(tag)}
                >
                  <CrossIcon />
                </IconContainer>
              }
            >{`${tag.label}: ${tag.value}`}</Tag>
          ))}
        </TagList>
      </TagsContainer>
    </Container>
  )
}

export { Filters }
