import { ModalFooter, ModalHeader, styled } from '@grupoboticario/flora-react'

const StyledModalHeader = styled(ModalHeader, {
  borderBottom: '1px solid $nonInteractiveAltAuxiliar',
  padding: '$5 0 $3',
  margin: '0 $5',
})

const StyledModalFooter = styled(ModalFooter, {
  borderTop: '1px solid $nonInteractiveAltAuxiliar',
  padding: '$2 0 $5',
  margin: '0 $5',
})

export { StyledModalFooter, StyledModalHeader }
