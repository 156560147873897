import { useId } from 'react'

import { FloraCheckbox as Checkbox } from '@grupoboticario/flora-react'

import { pt } from '@shared/locales'

import { DescriptionIcon } from '@shared/components/description-icon'
import { type DataTableColumn, type DataTableOrder } from '../../data-table.types'
import { useTableBodyContext, useTableContext } from '../../table-provider'
import { HeaderCell, StyledText } from './header-cell.styles'

interface HeaderCellBaseProps<T> {
  column: DataTableColumn<T>
  order?: DataTableOrder
  onSort?: () => void
}

const HeaderCellBase = <T,>({ column, onSort, order }: HeaderCellBaseProps<T>): JSX.Element => (
  <HeaderCell
    onClick={onSort}
    order={order}
    align="left"
    css={{
      $$mbWidth: column.widths.mb,
      $$tbWidth: column.widths.tb,
      $$dkWidth: column.widths.dk,
    }}
  >
    <StyledText size="bodyMediumShort" weight="medium">
      {column.label}
      {column.tooltip && <DescriptionIcon description={column.tooltip} />}
    </StyledText>
  </HeaderCell>
)

interface HeaderCellCheckbox<T> {
  records: T[]
}

const HeaderCellCheckbox = <T,>({ records }: HeaderCellCheckbox<T>): JSX.Element => {
  const { setRecordsChecked, recordsChecked } = useTableBodyContext()
  const { onCheckAllRecords } = useTableContext()
  const id = useId()

  const checkAllRecords = () => {
    if (recordsChecked.length === records.length) {
      setRecordsChecked([])
    } else {
      setRecordsChecked(records)
      onCheckAllRecords?.()
    }
  }

  return (
    <HeaderCell
      css={{
        $$mbWidth: '5%',
        $$tbWidth: '5%',
        $$dkWidth: '5%',
      }}
    >
      <Checkbox
        id={id}
        onChange={checkAllRecords}
        checked={recordsChecked.length === records.length}
        aria-label={pt.dataTable.checkboxRecordsLabel}
      />
    </HeaderCell>
  )
}

export { HeaderCellBase, HeaderCellCheckbox }
