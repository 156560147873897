import { Fragment } from 'react'

import { FloraButton as Button, Text } from '@grupoboticario/flora-react'
import { MinusCircleIcon, ThreeLinesHorizontal2Icon } from '@grupoboticario/flora-react-icons'

import { Badge } from '@shared/components'
import { pt } from '@shared/locales'

import { useTableContext } from '../table-provider'
import { ButtonsContainer, Container } from './heading.styles'

const Heading = (): JSX.Element => {
  const { heading, filter, state, subtitle } = useTableContext()
  const disableFilterButton = !state.filled && !state.error

  const count = filter?.tags
    .map((tag) => tag.id)
    .filter((id, index, list) => list.indexOf(id) === index).length

  const renderSubtitle = subtitle && (
    <Text
      as="small"
      size="exceptionsAuxiliarMedium"
      color="$nonInteractiveAuxiliar"
      css={{ display: 'block' }}
    >
      {subtitle}
    </Text>
  )

  return (
    <Fragment>
      <Container>
        <Text size="bodyLargeStandardMedium" weight="medium" as="h3">
          {heading}
          {renderSubtitle}
        </Text>

        {filter && (
          <ButtonsContainer>
            <Badge text={`+${count}`} show={Boolean(count)}>
              <Button
                hierarchy="secondary"
                has="iconLeft"
                icon={<ThreeLinesHorizontal2Icon />}
                onClick={filter.onFilter}
                disabled={disableFilterButton}
              >
                {pt.dataTable.filter}
              </Button>
            </Badge>

            <Button
              hierarchy="secondary"
              has="iconLeft"
              icon={<MinusCircleIcon />}
              onClick={filter.clearFilters}
              disabled={disableFilterButton || !count}
            >
              {pt.dataTable.clearFilters}
            </Button>
          </ButtonsContainer>
        )}
      </Container>
    </Fragment>
  )
}

export { Heading }
