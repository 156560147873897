import { Button, Flex, styled, Text } from '@grupoboticario/flora-react'

enum colors {
  WHITE = '$nonInteractiveAltPredominant',
  BRAND_ONE = '$backgroundAltTertiary',
  BRAND_TWO = '$actionableDefault',
  MEDIUM_LIGHT_TWO = '$medium-light-2',
  DARK = '$dark-dark',
  MEDIUM = '$nonInteractiveAuxiliar',
}

const CalendarHeader = styled('div', {
  backgroundColor: colors.BRAND_ONE,
  textAlign: 'center',
  padding: '$1',
})

const StyledButton = styled(Button, {
  flexGrow: 1,
  backgroundColor: `${colors.WHITE} !important`,
  color: `${colors.BRAND_ONE} !important`,

  '&:disabled': {
    color: `${colors.MEDIUM_LIGHT_TWO} !important`,
  },
})

const MonthLabel = styled(Text, {
  flexGrow: 2,
})

const HeaderContainer = styled(Flex, {
  borderBottom: `1px solid ${colors.MEDIUM_LIGHT_TWO}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '$lig',
})

const Container = styled('div', {
  '& [data-flora="input-wrapper"]': {
    minHeight: 0,
  },

  '.react-datepicker': {
    minWidth: '250px',
    fontFamily: 'inherit',
    border: '1px solid $nonInteractiveOutline',

    '&-popper': {
      zIndex: '$docked',
    },

    '&-wrapper': {
      width: '100%',
    },

    '&__header': {
      border: 'none',
      backgroundColor: 'transparent',
      padding: '0',
    },

    '&__month': {
      width: '100%',
      padding: '0 $2',
      margin: 0,

      '&-container': {
        width: '100%',
        fontSize: '0.625rem !important',
        padding: 0,
      },
    },

    '&__day': {
      width: 'calc(100% / 7)',
      margin: '$2 0',
      borderRadius: 0,

      '&:hover': {
        backgroundColor: '$actionableHover',
        color: '$actionableContent',
        borderRadius: 'initial',
      },

      '&-name': {
        width: 'calc(100% / 7)',
        color: colors.BRAND_ONE,
        margin: 0,
      },

      '&-names': {
        padding: '0 $2',
      },

      '&--today': {
        fontWeight: '$regular',
        backgroundColor: 'inherit',
      },

      '&--outside-month': {
        color: colors.MEDIUM,
      },

      '&--selecting-range-start': {
        backgroundColor: `$actionableActive !important`,
        borderTopLeftRadius: '$small',
        borderBottomLeftRadius: '$small',
      },
      '&--range-start': {
        borderTopLeftRadius: '$small',
        borderBottomLeftRadius: '$small',
      },
      '&--selecting-range-end': {
        backgroundColor: `$actionableActive !important`,
        borderTopRightRadius: '$small',
        borderBottomRightRadius: '$small',
      },
      '&--range-end': {
        borderTopRightRadius: '$small',
        borderBottomRightRadius: '$small',
      },

      '&--in-range': {
        backgroundColor: colors.BRAND_ONE,
        color: colors.WHITE,

        '&:hover, &:focus-visible': {
          backgroundColor: colors.BRAND_TWO,
        },
      },

      '&--in-selecting-range': {
        backgroundColor: '$actionableHover !important',
        color: colors.WHITE,
      },

      '&--disabled': {
        color: colors.MEDIUM,
      },
    },
  },
})

const InputContainer = styled('div', {
  boxShadow: 'inset 0 0 0 1px var(--flora--colors-nonInteractiveOutline)',
  borderRadius: '$small',
  position: 'relative',
  height: '48px',

  variants: {
    disabled: {
      true: {
        background: '$disabledBackground',
        svg: {
          color: '$disabledContent',
        },
        label: {
          color: '$disabledContent',
        },
      },
    },
    hasValue: {
      true: {
        label: {
          fontSize: '$auxiliar',
          transform: 'none',
          color: '$nonInteractivePredominant',
          top: 3,
        },
      },
    },
  },

  label: {
    left: 14,
    transform: 'translateY(-50%)',
    top: '50%',
    position: 'absolute',
    lineHeight: '$bodySmall',
    fontSize: '$bodySmall',
    fontWeight: '$regular',
    transition: 'all 0.2s ease-in-out 0s',
  },

  '&:focus-within label': {
    fontSize: '$auxiliar',
    transform: 'none',
    color: '$actionableDefault',
    top: 3,
  },

  input: {
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    position: 'absolute',
    padding: '20px 50px 0 14px',
    borderRadius: '$small',
    transition: 'box-shadow 0.2s ease-in-out 0s',
    fontSize: '$bodySmall',
    fontWeight: '$regular',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    '&:focus': {
      boxShadow: 'inset 0px 0px 0px 2px var(--flora--colors-actionableDefault);',
    },
  },

  svg: {
    position: 'absolute',
    right: '12px',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '$6',
    height: '$6',
    color: '$actionableDefault',
  },
})

export { CalendarHeader, Container, HeaderContainer, InputContainer, MonthLabel, StyledButton }
