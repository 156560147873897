import React from 'react'

import {
  FloraButton as Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalTrigger,
} from '@grupoboticario/flora-react'

import { Information, StyledModalHeader } from '@shared/components'
import { pt } from '@shared/locales'

import { useSectorsManagement } from '../../hooks'
import { useSectorsContext } from '../../providers'
import { ListSectors } from './list-sectors'

import {
  InformationContainer,
  ModalCloseButton,
  ModalFooter,
  StyledButton,
} from './sectors-management.styles'

const SectorsManagement: React.FC = () => {
  const { loading, items } = useSectorsContext()
  const { changeSectors, confirmChange, disabledEdition, sectorsSelected } = useSectorsManagement()

  return (
    <Modal>
      <ModalTrigger>
        <StyledButton size="small" hierarchy="secondary" disabled={loading}>
          {pt.sectorsFilter.manageSectors}
        </StyledButton>
      </ModalTrigger>

      <ModalContent>
        <ModalCloseButton />

        <StyledModalHeader>
          <Heading level={6}>{pt.sectorsFilter.manageSectorsActive}</Heading>
        </StyledModalHeader>

        <ModalBody>
          {disabledEdition && (
            <InformationContainer>
              <Information status="error" message={pt.sectorsFilter.noSectorsSelected} />
            </InformationContainer>
          )}

          <ListSectors
            sectors={items}
            sectorsSelected={sectorsSelected}
            onChangeSectors={changeSectors}
          />
        </ModalBody>

        <ModalFooter>
          <ModalTrigger>
            <Button hierarchy="secondary" size="small">
              {pt.sectorsFilter.cancelEdition}
            </Button>
          </ModalTrigger>

          <ModalTrigger>
            <Button disabled={disabledEdition} onClick={confirmChange} size="small">
              {pt.sectorsFilter.confirmEdition}
            </Button>
          </ModalTrigger>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export { SectorsManagement }
