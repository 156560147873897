import { AvatarContainer } from './avatar.styles'

const Avatar: React.FC<{ content: string }> = ({ content }) => {
  const getAvatarText = (): string => {
    const names = content.split(' ')
    const last = names.pop() as string
    const first = names[0] ?? ''

    const letter1 = first.charAt(0)
    const letter2 = last.charAt(0)

    return `${letter1}${letter2}`.toUpperCase()
  }

  return <AvatarContainer data-testid="data-block:avatar">{getAvatarText()}</AvatarContainer>
}

export { Avatar }
