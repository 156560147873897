import React from 'react'
import ReactDOMClient from 'react-dom/client'
import singleSpaReact from 'single-spa-react'

import { RootComponent } from './root.component'

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  renderType: 'createRoot',
  rootComponent: RootComponent,
  errorBoundary(err) {
    return <h1>Error on load segmentation MFE: {err.message}</h1>
  },
})

export const { bootstrap, mount, unmount } = lifecycles
