import { Text } from '@grupoboticario/flora-react'
import { ExclamationMarkTriangleFillIcon } from '@grupoboticario/flora-react-icons'

import { InfoContainer, Opacity } from './information.styles'

interface InformationProps {
  status: 'error' | 'success' | 'warning'
  message: string
  icon?: JSX.Element
}

const Information = ({ status, message, icon }: InformationProps): JSX.Element => (
  <InfoContainer status={status}>
    <Opacity status={status} />
    {icon ?? statusIcon[status]}
    <Text size="bodySmallShortMedium">{message}</Text>
  </InfoContainer>
)

const statusIcon = {
  error: <ExclamationMarkTriangleFillIcon data-testid="error-icon" />,
  success: <ExclamationMarkTriangleFillIcon data-testid="success-icon" />,
  warning: <ExclamationMarkTriangleFillIcon data-testid="warning-icon" />,
}

export { Information }
