import { ScrollArea, styled } from '@grupoboticario/flora-react'
import { Table as TableFT } from '@grupoboticario/flora-team-react-table'

const tableHeight = 630

const Container = styled('div', {
  background: '$backgroundPrimary',
  borderRadius: '$medium',
  border: '1px solid $nonInteractiveAltAuxiliar',
  padding: '$2',
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',

  '@tablet': {
    padding: '$6',
  },
})

const StateContainer = styled('div', {
  height: tableHeight + 94,
  display: 'flex',
  justifyContent: 'center',
})

const TableContainer = styled(ScrollArea, {
  paddingRight: '$3',
  height: tableHeight,
  overflow: 'hidden',
})

const Table = styled(TableFT.Root, {
  borderSpacing: 'unset',
  borderCollapse: 'unset',
  '-webkit-user-select': 'none' /* Safari */,
  '-ms-user-select': 'none' /* IE 10 and IE 11 */,
  'user-select': 'none',

  '& tr > td:first-of-type': {
    borderRadius: 'unset',
  },

  '& tr > td:last-of-type': {
    borderRadius: 'unset',
  },

  '& th, td': {
    background: 'unset',
    borderBottom: '1px solid $nonInteractiveOutline',
    padding: '0 $6',
  },

  '& th': {
    height: '64px',
    verticalAlign: 'middle',
  },

  '& td': {
    height: '56px',
  },

  '& tbody > tr:nth-child(odd)': {
    background: '$backgroundPrimary',
  },
})

export { Container, StateContainer, Table, TableContainer }
