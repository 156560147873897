import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { HttpResult, type IHttpResult } from '@grupoboticario/vdi-mfe-utils'

import { useIsMounted } from '@shared/hooks'

import { ApiContext } from './api.provider'
import { MetaState, type API, type AsyncState } from './api.types'

const useApi = (): API => {
  const context = useContext(ApiContext)
  if (!context) throw new Error('Context not provided')
  return context
}

const useAsyncState = (state: MetaState): AsyncState => ({
  error: state === MetaState.Error,
  filled: state === MetaState.Fullfilled,
  loading: state === MetaState.Loading,
  idle: state === MetaState.Idle,
  notFound: state === MetaState.NotFound,
})

interface UseApiDataInput<TData> {
  dataSource: keyof API
  fetchOnMount?: boolean
  params?: Record<string, unknown>
  onSuccess?: (result: TData) => void
}

type UseApiDataOutput<TData> = UseApiDataFilled<TData> | UseApiDataUnfilled

interface UseApiDataFilled<TData> extends UseApiDataCommon<TData> {
  filled: true
  data: TData
}

interface UseApiDataUnfilled extends UseApiDataCommon {
  filled: false
  data: undefined
}

interface UseApiDataCommon<TData = undefined> extends AsyncState {
  getData: () => Promise<IHttpResult<TData, unknown>>
}

interface State<TData> {
  data?: TData
  status: MetaState
}

const useApiData = <TData>({
  dataSource,
  fetchOnMount = true,
  params = {},
  onSuccess,
}: UseApiDataInput<TData>): UseApiDataOutput<TData> => {
  const [stateResult, setStateResult] = useState<State<TData>>({ status: MetaState.Idle })
  const state = useAsyncState(stateResult.status)
  const isMounted = useIsMounted()
  const api = useApi()
  const ref = useRef(params)

  const getApiData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (params?: any) => {
      setStateResult((r) => ({ ...r, status: MetaState.Loading }))
      const result = await api[dataSource]<TData>({ ...ref.current, ...params })

      if (!isMounted()) {
        return HttpResult.ok({} as unknown as TData, 200)
      }

      if (result.ok) {
        setStateResult({ data: result.value, status: MetaState.Fullfilled })
        onSuccess?.(result.value)
      } else {
        if (result.code === 404) {
          setStateResult((r) => ({ ...r, status: MetaState.NotFound }))
        } else {
          setStateResult((r) => ({ ...r, status: MetaState.Error }))
        }
      }

      return result
    },
    [isMounted, api, dataSource],
  )

  if (fetchOnMount) {
    // há casos em que não precisamos chamar na montagem do componente
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      void getApiData(ref.current)
    }, [getApiData])
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return {
    getData: getApiData,
    data: stateResult.data,
    ...state,
  }
}

export { useApi, useApiData, useAsyncState, type UseApiDataOutput }
