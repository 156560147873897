import { Flex, styled, Text } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  position: 'relative',
  alignItems: 'center',
  alignSelf: 'stretch',
  minHeight: '$18',
  backgroundColor: '$backgroundPrimary',
  borderRadius: '$medium',
  paddingTop: '$4',
  paddingRight: '$8',
  paddingBottom: '$4',
  paddingLeft: '$12',

  '@tablet': {
    paddingLeft: '$16',
  },

  '> span': {
    position: 'absolute',
    top: '$6',
    left: '$4',
    display: 'inline-flex',

    '@tablet': {
      top: 'calc(50% - 24px / 2)',
      left: '$8',
    },
  },
})

const Content = styled(Flex, {
  flexDirection: 'column',
})

const baseTextStyle = {
  display: 'inline-flex',
  fontWeight: '$medium',
  marginBottom: 0,
}

const Description = styled(Text, {
  ...baseTextStyle,
  width: '100%',
  minHeight: '$6',
  color: '$nonInteractiveEmphasis',
  lineHeight: '$normal',
  overflowWrap: 'anywhere',
  zIndex: '$docked',
})

const Title = styled(Text, {
  ...baseTextStyle,
  color: '$nonInteractiveAuxiliar',
  fontSize: '0.6875rem',
  lineHeight: '$short',
  letterSpacing: '0.08rem',
  textTransform: 'uppercase',
  marginBottom: 0,
})

export { Container, Content, Description, Title }
