import { InformationCircleFillIcon } from '@grupoboticario/flora-react-icons'
import { Alert } from './not-found-state.styles'

import { pt } from '@shared/locales'

interface NotFoundStateProps {
  text?: string
}

const NotFoundState = ({ text }: NotFoundStateProps): JSX.Element => {
  return (
    <Alert>
      <InformationCircleFillIcon size="24px" color="$statusInfoNonInteractiveEmphasis" />
      <p>{text ?? pt.notFoundState.defaultMessage}</p>
    </Alert>
  )
}

export { NotFoundState, NotFoundStateProps }
