import { pt } from '@shared/locales'

import { FieldTypes, type FilterFieldGroup } from './filters-provider.types'

const fieldsGroups: FilterFieldGroup[] = [
  {
    description: pt.filtersProvider.group.mission,
    fields: [
      {
        id: 'mission',
        type: FieldTypes.DROPDOWN_ASYNC,
        label: pt.filtersProvider.field.mission.label,
        multiple: false,
        dataSource: 'getMissions',
        filters: [{ name: 'mission', label: pt.filtersProvider.field.mission.filter }],
      },
    ],
  },
  {
    description: pt.filtersProvider.group.triggers,
    fields: [
      {
        id: 'hasActivationRecordLastCycle',
        type: FieldTypes.SWITCH,
        label: pt.filtersProvider.field.noTriggeringInTheCycle.label,
        filters: [
          {
            name: 'hasActivationRecordLastCycle',
            label: pt.filtersProvider.field.noTriggeringInTheCycle.filter,
          },
        ],
      },
      {
        id: 'activationRecord',
        type: FieldTypes.DATE_RANGE,
        label: pt.filtersProvider.field.lastActivationPeriod.label,
        maxDays: 30 * 24 * 60 * 60 * 1000,
        isDisabled: (form) => form.hasActivationRecordLastCycle === 'true',
        filters: [
          {
            name: 'activationRecordInit',
            label: pt.filtersProvider.field.lastActivationPeriod.init,
          },
          {
            name: 'activationRecordEnd',
            label: pt.filtersProvider.field.lastActivationPeriod.end,
          },
        ],
      },
    ],
  },
  {
    description: pt.filtersProvider.group.attendance,
    fields: [
      {
        id: 'useCycleRange',
        type: FieldTypes.SWITCH,
        label: pt.filtersProvider.field.anyAttendanceCycle.label,
        filters: [
          { label: pt.filtersProvider.field.anyAttendanceCycle.filter, name: 'useCycleRange' },
        ],
      },
      {
        id: 'tabulation',
        type: FieldTypes.DATE_RANGE,
        label: pt.filtersProvider.field.tabulation.label,
        maxDays: 30 * 24 * 60 * 60 * 1000,
        isDisabled: (form) => form.useCycleRange === 'true',
        filters: [
          {
            name: 'tabulationInit',
            label: pt.filtersProvider.field.tabulation.filterInit,
          },
          {
            name: 'tabulationEnd',
            label: pt.filtersProvider.field.tabulation.filterEnd,
          },
        ],
      },
    ],
  },
  {
    description: pt.filtersProvider.group.activity,
    fields: [
      {
        id: 'cycle',
        type: FieldTypes.DROPDOWN,
        label: pt.filtersProvider.field.inactivityLevel.label,
        multiple: true,
        filters: [
          {
            name: 'cycle',
            label: pt.filtersProvider.field.inactivityLevel.filter,
          },
        ],
        options: [
          { value: 'A0', label: 'A0' },
          { value: 'A1', label: 'A1' },
          { value: 'A2', label: 'A2' },
          { value: 'A3', label: 'A3' },
          { value: 'I4', label: 'I4' },
          { value: 'I5', label: 'I5' },
          { value: 'I6', label: 'I6' },
          { value: 'I7', label: 'I7' },
          { value: 'I8', label: 'I8' },
          { value: 'I9', label: 'I9' },
          { value: 'I10', label: 'I10' },
          { value: 'I11', label: 'I11' },
          { value: 'I12', label: 'I12' },
          { value: 'I13', label: 'I13' },
          { value: 'I14', label: 'I14' },
          { value: 'I15', label: 'I15' },
        ],
      },
      {
        id: 'currentCycle',
        type: FieldTypes.DROPDOWN_ASYNC,
        label: pt.filtersProvider.field.currentCycle.label,
        multiple: true,
        dataSource: 'getCurrentCycles',
        filters: [{ name: 'currentCycle', label: pt.filtersProvider.field.currentCycle.filter }],
      },
      {
        id: 'harvest',
        type: FieldTypes.DROPDOWN_ASYNC,
        label: pt.filtersProvider.field.harvest.label,
        multiple: true,
        dataSource: 'getHarvestOptions',
        filters: [{ name: 'harvest', label: pt.filtersProvider.field.harvest.filter }],
      },
      {
        id: 'blocked',
        type: FieldTypes.CHECKBOX,
        label: pt.filtersProvider.field.blocked.label,
        filters: [{ name: 'blocked', label: pt.filtersProvider.field.blocked.filter }],
        options: [
          { value: 'false', label: pt.filtersProvider.field.blocked.options.nonBlocked },
          { value: 'true', label: pt.filtersProvider.field.blocked.options.blocked },
        ],
      },
    ],
  },
  {
    description: pt.filtersProvider.group.fidelity,
    fields: [
      {
        id: 'fidelity',
        type: FieldTypes.DROPDOWN_ASYNC,
        label: pt.filtersProvider.field.fidelity.label,
        multiple: true,
        dataSource: 'getFidelityRange',
        filters: [{ name: 'roles', label: pt.filtersProvider.field.fidelity.filter }],
      },
      {
        id: 'nextRangeValue',
        type: FieldTypes.INPUT,
        label: pt.filtersProvider.field.nextRangeValue.label,
        style: 'currency',
        filters: [
          {
            name: 'nextRangeValue',
            label: pt.filtersProvider.field.nextRangeValue.filter,
          },
        ],
      },
    ],
  },
  {
    description: pt.filtersProvider.group.financial,
    fields: [
      {
        id: 'creditMin',
        type: FieldTypes.INPUT,
        label: pt.filtersProvider.field.creditMin.label,
        style: 'currency',
        grid: 'half',
        filters: [{ name: 'creditMin', label: pt.filtersProvider.field.creditMin.filter }],
      },
      {
        id: 'creditMax',
        type: FieldTypes.INPUT,
        label: pt.filtersProvider.field.creditMax.label,
        style: 'currency',
        grid: 'half',
        filters: [{ name: 'creditMax', label: pt.filtersProvider.field.creditMax.filter }],
      },
      {
        id: 'increaseCycleLimit',
        type: FieldTypes.SWITCH,
        label: pt.filtersProvider.field.increaseCycleLimit.label,
        filters: [
          { name: 'increaseCycleLimit', label: pt.filtersProvider.field.increaseCycleLimit.filter },
        ],
      },
      {
        id: 'negotiationTitle',
        type: FieldTypes.CHECKBOX,
        label: pt.filtersProvider.field.negotiationTitle.label,
        filters: [
          { name: 'negotiationTitle', label: pt.filtersProvider.field.negotiationTitle.label },
        ],
        options: [
          { value: 'true', label: pt.common.yes },
          { value: 'false', label: pt.common.no },
        ],
      },
    ],
  },
  {
    description: pt.filtersProvider.group.exclusivities,
    fields: [
      {
        id: 'promotions',
        type: FieldTypes.DROPDOWN_ASYNC,
        label: pt.filtersProvider.field.promotions.label,
        multiple: true,
        dataSource: 'getPromotions',
        filters: [{ name: 'promotion', label: pt.filtersProvider.field.promotions.filter }],
      },
      {
        id: 'birthday',
        type: FieldTypes.DATE_RANGE,
        label: pt.filtersProvider.field.birthdate.label,
        maxDays: 30 * 24 * 60 * 60 * 1000,
        filters: [
          {
            name: 'birthInitDate',
            label: pt.filtersProvider.field.birthdate.filterInit,
          },
          {
            name: 'birthEndDate',
            label: pt.filtersProvider.field.birthdate.filterEnd,
          },
        ],
      },
    ],
  },
  {
    description: pt.filtersProvider.group.shoppingHabits,
    fields: [
      {
        id: 'activatedERCycle',
        type: FieldTypes.CHECKBOX,
        label: pt.filtersProvider.field.activatedERCycle.label,
        filters: [
          { name: 'activatedERCycle', label: pt.filtersProvider.field.activatedERCycle.label },
        ],
        options: [
          { value: 'true', label: pt.common.yes },
          { value: 'false', label: pt.common.no },
        ],
      },
      {
        id: 'boughtMultiBrand',
        type: FieldTypes.CHECKBOX,
        label: pt.filtersProvider.field.boughtMultiBrand.label,
        filters: [
          { name: 'isMultiBrandActive', label: pt.filtersProvider.field.boughtMultiBrand.label },
        ],
        options: [
          { value: 'true', label: pt.common.yes },
          { value: 'false', label: pt.common.no },
        ],
      },
      {
        id: 'brandsBought',
        type: FieldTypes.DROPDOWN_ASYNC,
        label: pt.filtersProvider.field.brandsBought.label,
        multiple: true,
        dataSource: 'getBrandsBought',
        filters: [{ name: 'brandsBought', label: pt.filtersProvider.field.city.filter }],
      },
      {
        id: 'appAdhesion',
        type: FieldTypes.DROPDOWN_ASYNC,
        label: pt.filtersProvider.field.appAdhesion.label,
        multiple: true,
        dataSource: 'getAppAdhesion',
        filters: [{ name: 'appAdhesion', label: pt.filtersProvider.field.appAdhesion.label }],
      },
    ],
  },
  {
    description: pt.filtersProvider.group.region,
    fields: [
      {
        id: 'city',
        type: FieldTypes.DROPDOWN_ASYNC,
        label: pt.filtersProvider.field.city.label,
        multiple: true,
        dataSource: 'getCities',
        filters: [{ name: 'city', label: pt.filtersProvider.field.city.filter }],
      },
      {
        id: 'cs',
        type: FieldTypes.DROPDOWN_ASYNC,
        label: pt.filtersProvider.field.cs.label,
        multiple: true,
        dataSource: 'getCS',
        filters: [{ name: 'cs', label: pt.filtersProvider.field.cs.filter }],
      },
      {
        id: 'zoneer',
        type: FieldTypes.DROPDOWN_ASYNC,
        label: pt.filtersProvider.field.zoneer.label,
        multiple: true,
        dataSource: 'getZonesER',
        filters: [{ name: 'zoneer', label: pt.filtersProvider.field.zoneer.filter }],
      },
    ],
  },
]

export { fieldsGroups }
