import { Fragment, useRef, useState, type MouseEvent } from 'react'
import { createPortal } from 'react-dom'

import { FloraButton as Button, Text, type CSS } from '@grupoboticario/flora-react'
import { CheckCircleIcon, TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons'

import { useIsMounted } from '@shared/hooks'
import { pt } from '@shared/locales'

import { Feedback } from './copy-button.styles'

type CopyButtonProps = CopyButtonAsyncProps | CopyButtonPropsSyncProps

interface CopyButtonPropsBase {
  description: string
  textToCopy?: string
  onCopy?: (content: string) => void
}

interface CopyButtonAsyncProps extends CopyButtonPropsBase {
  async: true
  getContent: () => Promise<string>
}

interface CopyButtonPropsSyncProps extends CopyButtonPropsBase {
  async?: false
  content: string
}

const CopyButton: React.FC<CopyButtonProps> = (props) => {
  const [copied, setCopied] = useState(false)
  const isMouted = useIsMounted()

  const ref = useRef<HTMLButtonElement>(null)

  const copyContent = async (ev: MouseEvent<HTMLButtonElement>): Promise<void> => {
    ev.stopPropagation()

    try {
      const content = props.async ? await props.getContent() : props.content
      const selectedText = props.textToCopy ?? content

      if (!isMouted) {
        return
      }

      await navigator.clipboard.writeText(selectedText)
      props.onCopy?.(content)
      setCopied(true)
      setTimeout(() => setCopied(false), 1500)
    } catch {
      setCopied(false)
    }
  }

  const getPosition = (): CSS | undefined => {
    if (!ref.current) return

    const rect = ref.current.getBoundingClientRect()
    const left = Math.round(rect.right + 10)
    const top = rect.top

    return { top, left }
  }

  const feedbackToast =
    copied &&
    createPortal(
      <Feedback css={getPosition()}>
        <CheckCircleIcon css={{ color: '$nonInteractiveAltPredominant' }} size={20} />
        <Text color="$nonInteractiveAltPredominant" size="exceptionsAuxiliarRegular">
          {props.description}
        </Text>
      </Feedback>,
      document.body,
    )

  return (
    <Fragment>
      <Button
        ref={ref}
        size="small"
        hierarchy="tertiary"
        styleSemantic="neutral"
        surfaceColor="light"
        has="iconOnly"
        onClick={copyContent}
        aria-label={pt.copyButton.copyContent}
        icon={<TwoPiledSquaresIcon size={16} color="$actionableDefault" />}
      />

      {feedbackToast}
    </Fragment>
  )
}

export { CopyButton, type CopyButtonProps }
