import { styled, Tab as FloraTab, Tabs as FloraTabs } from '@grupoboticario/flora-react'

const Tabs = styled(FloraTabs, {
  width: '100%',
})

const Tab = styled(FloraTab, {
  '&:focus-visible': {
    outlineOffset: '-8px',
  },
})

export { Tabs, Tab }
