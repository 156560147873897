import {
  Checkbox as CheckboxFlora,
  ScrollArea as ScrollAreaFlora,
  styled,
} from '@grupoboticario/flora-react'

const Table = styled('table', {
  width: '100%',
  tableLayout: 'auto',

  '*': {
    fontWeight: 'inherit',
  },

  '& td, th': {
    verticalAlign: 'middle',

    '&:first-of-type': {
      width: '40px',
      padding: '$3',
    },

    '&:nth-of-type(2)': {
      width: '120px',
    },

    padding: '$2',
  },

  '& tbody > tr:not(:last-child)': {
    borderBottom: '1px solid $nonInteractiveAltAuxiliar',
  },

  '& thead > tr': {
    borderBottom: '1px solid $nonInteractiveAltAuxiliar',
  },

  '& tbody > tr': {
    cursor: 'pointer',
  },
})

const ScrollArea = styled(ScrollAreaFlora, {
  height: 300,
  verticalAlign: 'baseline',
})

const Checkbox = styled(CheckboxFlora, {
  '&:focus-visible': {
    outlineWidth: '2px',
    outlineStyle: 'dashed',
    outlineOffset: '2px',
    outlineColor: '$backgroundAltPrimary',
  },
})

export { Checkbox, ScrollArea, Table }
