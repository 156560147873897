import React from 'react'

import { Flex } from '@grupoboticario/flora-react'

import { DescriptionIcon } from '@shared/components'

import { StyledDescription } from './description.styles'

interface DescriptionProps {
  description?: string
  descriptionTooltip?: string
}

const Description: React.FC<DescriptionProps> = ({ description, descriptionTooltip }) => (
  <Flex align="center" gap="$2">
    <StyledDescription as="h5">{description}</StyledDescription>

    {descriptionTooltip && <DescriptionIcon description={descriptionTooltip} />}
  </Flex>
)

export { Description, type DescriptionProps }
