import {
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  styled,
} from '@grupoboticario/flora-react'

const StyledDrawerHeader = styled(DrawerHeader, {
  padding: '$6 $15 $6 $6',
  borderBottom: '1px solid $nonInteractiveAltAuxiliar',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: '$2',

  '@tablet': {
    flexWrap: 'nowrap',
  },
})

const StyledDrawerFooter = styled(DrawerFooter, {
  padding: '$4 $6',
  borderTop: '1px solid $nonInteractiveAltAuxiliar',
})

const StyledDrawerContent = styled(DrawerContent, {
  '@tablet': {
    width: 'auto !important',
  },
})

const StyledDrawerBody = styled(DrawerBody, {
  height: '100%',
})

export { StyledDrawerBody, StyledDrawerContent, StyledDrawerFooter, StyledDrawerHeader }
