import { pt } from '@shared/locales'
import { type DrawerFactory } from '@shared/providers'

import { ObjectivesFilter, ObjectivesFilterAction } from '../objectives-filter'
import { ObjectivesFormProvider } from '../providers'

const formId = 'form:objectives-filter'

const objectivesDrawer: DrawerFactory = ({ close }) => ({
  wrapper: ObjectivesFormProvider,
  header: { title: pt.objectivesFilter.drawer.headerText },
  body: {
    node: <ObjectivesFilter formId={formId} onSumbit={close} />,
  },
  footer: {
    node: <ObjectivesFilterAction formId={formId} />,
  },
})

export { objectivesDrawer }
