import { styled, Text, type CSS } from '@grupoboticario/flora-react'

const getVariantStyles = (status: string): CSS => {
  const backgroundColor = status === 'success' ? '$success-standard' : '$error-alt'

  return {
    paddingLeft: '$3',

    '&::before': {
      content: '',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '$1',
      height: '$6',
      borderRadius: '$medium',
      backgroundColor,
    },
  }
}

const StatusLabel = styled(Text, {
  position: 'relative',
  minHeight: '$6',
  color: '$nonInteractivePredominant',
  marginBottom: 0,
  overflowWrap: 'anywhere',
  paddingTop: '$1',

  variants: {
    status: {
      error: getVariantStyles('error'),
      success: getVariantStyles('success'),
    },
  },
})

export { StatusLabel }
