import React from 'react'

import { Spinner } from '@grupoboticario/flora-react'

import { SectorTag } from '@sectors-filter/components'
import { useSectorsContext, useSectorsSelectedContext } from '@sectors-filter/providers'
import { pt } from '@shared/locales'

import { Container, ContainerTags, Title } from './active-sectors.styles'

const ActiveSectors: React.FC = () => {
  const { loading, filled } = useSectorsContext()
  const { sectorsSelected } = useSectorsSelectedContext()

  return (
    <Container>
      <Title>{pt.sectorsFilter.activeSectors}</Title>

      <ContainerTags css={{ paddingTop: loading ? '$3' : '5px' }}>
        {loading && <Spinner data-testid="sectors-loading" color="$9" size="xs" />}

        {filled &&
          sectorsSelected.map((sectorId) => (
            <SectorTag key={sectorId} sector={sectorId} variant="primary" />
          ))}
      </ContainerTags>
    </Container>
  )
}

export { ActiveSectors }
