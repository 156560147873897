import React from 'react'

import { Tooltip } from '@grupoboticario/flora-react'
import { QuestionMarkCircleIcon } from '@grupoboticario/flora-react-icons'

interface DescriptionIconProps {
  description: string
}

const DescriptionIcon: React.FC<DescriptionIconProps> = ({ description }) => (
  <Tooltip
    icon={<QuestionMarkCircleIcon />}
    align="start"
    direction="top"
    showArrow
    size="standard"
    text={description}
  >
    <QuestionMarkCircleIcon
      data-testid="description-icon"
      size={12}
      color="$nonInteractiveAuxiliar"
    />
  </Tooltip>
)

export { DescriptionIcon }
