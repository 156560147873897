import { useCallback, useEffect, useState } from 'react'

import { MetaState, useApi, useAsyncState, type APIParams, type AsyncState } from '@shared/api'
import { useDebouce } from '@shared/hooks'
import { useFiltersContext } from '@shared/providers'

import { useListContext } from '../providers'
import { Reseller, type ResellerList, type ResellerListDTO } from '../reseller-list.types'

interface UseResellerList extends ResellerList, AsyncState {
  getList: () => void
  resellersFull: Reseller[]
}

const useResellerList = (): UseResellerList => {
  const { pagination, current, ordination, changePagination } = useFiltersContext()
  const { list, setList } = useListContext()
  const state = useAsyncState(list.status)
  const api = useApi()
  const [resellersFull, setResellersFull] = useState<Reseller[]>([])

  const getResellerList = useDebouce(async (params: APIParams) => {
    setList((p) => ({ ...p, resellers: [], status: MetaState.Loading }))
    const result = await api.getResellerList<ResellerListDTO>(params)

    if (result.ok) {
      setList({
        resellers: result.value.data,
        status: MetaState.Fullfilled,
        updatedAt: result.value.metadata?.updatedAt,
        isExtendedOrdering: result.value.metadata?.isExtendedOrdering,
        extendedOrderingPeriod: result.value.metadata?.extendedOrderingPeriod,
      })
      changePagination(result.value.pagination)
    } else {
      setList((p) => ({ ...p, resellers: [], status: MetaState.Error }))
    }
  }, 400)

  const getList = useCallback(() => {
    if (!current.sectorId.length) return

    return getResellerList({
      page: pagination.page,
      limit: pagination.pageSize,
      ...ordination,
      ...current,
    })
  }, [current, getResellerList, pagination.page, pagination.pageSize, ordination])

  const getResellerListFull = useDebouce(async (params: APIParams) => {
    const result = await api.getSegmentationFull<ResellerListDTO>(params)
    if (result.ok) {
      setResellersFull(result.value.data)
    } else {
      setResellersFull([])
    }
  }, 400)

  useEffect(() => {
    void getList()
  }, [getList])

  const getFullList = useCallback(() => {
    if (!current.sectorId.length) return
    return getResellerListFull({ ...current } as APIParams)
  }, [current, getResellerListFull])

  useEffect(() => {
    void getFullList()
  }, [getFullList])

  return {
    ...state,
    ...list,
    getList,
    resellersFull,
  }
}

export { useResellerList }
