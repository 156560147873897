import React, { type ChangeEvent } from 'react'

import { Dropdown, DropdownItem } from '@grupoboticario/flora-react'
import { type FilterFieldDropdown } from '@shared/providers'

import { type ObjectivesFormHandler } from '../../objectives-filter.types'

type DropdownFieldProps = FilterFieldDropdown & ObjectivesFormHandler<string | string[]>

const DropdownField: React.FC<DropdownFieldProps> = ({
  onChange,
  options = [],
  disabled,
  filters,
  multiple,
  label,
  form,
}) => {
  const [filter] = filters
  let value = form[filter.name] ?? ''

  const handleChange = (ev: ChangeEvent<HTMLInputElement>): void => {
    onChange({ [filter.name]: ev.target.value })
  }

  if (multiple) {
    value = form[filter.name] ?? []
    label += value.length > 0 ? ` (${value.length})` : ''
  }

  return (
    <Dropdown
      id={`filter-field-${filter.name}`}
      value={value}
      name={filter.name}
      labelText={label}
      multiple={multiple}
      onChange={handleChange}
      disabled={disabled}
    >
      {options.map((option) => (
        <DropdownItem key={option.value} value={option.value} css={{ height: 'auto' }}>
          {option.label}
        </DropdownItem>
      ))}
    </Dropdown>
  )
}

export { DropdownField }
