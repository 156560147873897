import { Flex, styled } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
})

export { Container }
