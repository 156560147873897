import { Fragment, useLayoutEffect, useState } from 'react'

import { FloraButton as Button } from '@grupoboticario/flora-react'
import { ArrowDownIcon, ArrowUpIcon } from '@grupoboticario/flora-react-icons'

import { StyledList } from '../list.styles'
import { type ListCollapseProps } from '../list.types'
import { ButtonContainer, Collapse } from './list-collapse.styles'

const ListCollapse = <T,>({
  collapseItemsCount,
  collapseLessLabel,
  collapseMoreLabel,
  data,
  renderItem,
}: ListCollapseProps<T>): JSX.Element => {
  const [collapsed, setCollapsed] = useState(false)
  const [height, setHeight] = useState({ retracted: 0, expanded: 0 })

  let itemsHeight = 16
  let listHeight = 0
  const hasMoreData = data.length > collapseItemsCount
  const hasItems = data.length > 0

  const handleListRef = (node: HTMLUListElement | null): void => {
    if (node) {
      listHeight = node.clientHeight
    }
  }

  const handleItemRef = (node: HTMLLIElement | null): void => {
    if (node) {
      itemsHeight += node.clientHeight
    }
  }

  const showedItems = data.slice(0, collapseItemsCount).map((item, index) => (
    <li aria-hidden="false" ref={handleItemRef} key={index}>
      {renderItem(item, index)}
    </li>
  ))

  const hiddedItems = data.slice(collapseItemsCount, data.length).map((item, index) => (
    <li aria-hidden={!collapsed} key={index}>
      {renderItem(item, index)}
    </li>
  ))

  useLayoutEffect(() => {
    setHeight({
      expanded: listHeight,
      retracted: itemsHeight,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      {hasItems && (
        <Collapse
          css={{
            height: collapsed ? height.expanded : height.retracted,
          }}
        >
          <StyledList alternate ref={handleListRef}>
            {showedItems}
            {hiddedItems}
          </StyledList>
        </Collapse>
      )}

      {hasItems && hasMoreData && (
        <ButtonContainer>
          <Button
            size="small"
            hierarchy="secondary"
            styleSemantic="neutral"
            onClick={() => setCollapsed((e) => !e)}
            has="iconLeft"
            icon={collapsed ? <ArrowUpIcon size={16} /> : <ArrowDownIcon size={16} />}
          >
            {collapsed ? collapseLessLabel : collapseMoreLabel}
          </Button>
        </ButtonContainer>
      )}
    </Fragment>
  )
}

export { ListCollapse }
