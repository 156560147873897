import { styled } from '@grupoboticario/flora-react'

const justifyContent = 'space-between'

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent,
  gap: '$2',

  '@tablet': {
    flexDirection: 'row',
    justifyContent,
    alignItems: 'center',
  },
})

const ButtonsContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent,
  gap: '$2',
})

export { ButtonsContainer, Container }
