import { pt } from '@shared/locales'

import { GetStep, StepStateMachine } from '../activation-register.types'

const dispatchStep: GetStep = (status) => ({
  id: 'dispatch',
  description: pt.activationRegister.dispatchStep.description,
  status,
})

const copyStep: GetStep = (status) => ({
  id: 'copy',
  description: pt.activationRegister.copyStep.description,
  status,
})

const registerStep: GetStep = (status) => ({
  id: 'register',
  description: pt.activationRegister.registerStep.description,
  status,
})

const registerStepError: GetStep = (status) => ({
  id: 'registerError',
  description: pt.activationRegister.registerStepError.description,
  status,
})

const machine: Record<string, StepStateMachine> = {
  send: {
    on: {
      next: [copyStep('done'), dispatchStep('done'), registerStep('current')],
      error: [copyStep('done'), dispatchStep('error'), registerStepError('current')],
    },
  },
  register: {
    on: {
      back: [copyStep('done'), dispatchStep('current'), registerStep('await')],
    },
  },
  registerError: {
    on: {
      back: [copyStep('done'), dispatchStep('current'), registerStep('await')],
    },
  },
}

export { copyStep, dispatchStep, machine, registerStep, registerStepError }
