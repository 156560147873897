import React from 'react'

import { FloraProvider } from '@grupoboticario/flora-react'
import { authService } from '@vdi/auth-service'
import { ConfigCatProvider, User } from 'configcat-react'

import { ApiProvider, createApi } from '@shared/api'
import { env } from '@shared/env'
import { DrawerProvider, FiltersProvider } from '@shared/providers'

import { App } from './app'

const { geraId } = authService.userData
const defaultUser = new User(geraId)

const RootComponent: React.FC = () => {
  return (
    <React.StrictMode>
      <ConfigCatProvider sdkKey={env.CONFIGCAT_SDK_KEY} options={{ defaultUser }}>
        <FloraProvider>
          <ApiProvider api={createApi()}>
            <FiltersProvider>
              <DrawerProvider>
                <App />
              </DrawerProvider>
            </FiltersProvider>
          </ApiProvider>
        </FloraProvider>
      </ConfigCatProvider>
    </React.StrictMode>
  )
}

export { RootComponent }
