import { useMemo } from 'react'

import { FloraButton as Button, Text } from '@grupoboticario/flora-react'
import { TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons'

import { useEvents } from '@shared/events'
import { pt } from '@shared/locales'

import { DataTableTextColumn } from '../data-table.types'
import { useTableBodyContext, useTableContext } from '../table-provider'
import { ActionsContainer } from './actions.styles'
import { useFiltersContext } from '@shared/providers'

type ActionProps<T> = { allRecords: T[] }

const Actions = <T,>(props: ActionProps<T>): JSX.Element | null => {
  const events = useEvents()
  const filterContext = useFiltersContext()
  const { recordsChecked, setRecordsChecked } = useTableBodyContext<T>()
  const { columns, actions, onCopyRecords } = useTableContext<T>()

  const copiablesColumns = useMemo(
    () =>
      columns.filter(
        (c) => (c.type === 'text' || c.type === 'currency' || c.type === 'phone') && c.copyable,
      ),
    [columns],
  ) as DataTableTextColumn<unknown>[]

  const getRecordContent = (r: T) =>
    copiablesColumns.map((c) => c.onBeforeCopy?.(r[c.key]) ?? r[c.key]).join('\t')

  const doCopy = async (records: T[]) => {
    const content = records.map(getRecordContent).join('\n')
    await navigator.clipboard.writeText(content)
    actions.onCopyData?.(records)
    setRecordsChecked([])
  }

  const copyRecords = async (): Promise<void> => {
    onCopyRecords?.()
    await doCopy(recordsChecked)
  }

  const copyAllRecords = async (): Promise<void> => {
    onCopyRecords?.()
    if (props.allRecords.length) {
      await doCopy(props.allRecords)
    }
  }

  if (!recordsChecked.length) {
    return null
  }

  const onClickButton = (): void => {
    copyRecords()
    events.clickCopyRecordsOpenModal()
  }

  const onClickCopyAll = (): void => {
    copyAllRecords()
    events.clickCopyAllRecordsOpenModal()
  }

  return (
    <ActionsContainer>
      <Text size="exceptionsAuxiliarMedium" color="$nonInteractiveAuxiliar" weight="medium">
        {pt.actions.checkedRecords(recordsChecked.length)}
      </Text>

      <Button
        onClick={onClickButton}
        hierarchy="secondary"
        size="small"
        has="iconLeft"
        icon={<TwoPiledSquaresIcon />}
        aria-label={pt.actions.ariaLabelCopyRecords}
      >
        {pt.actions.copyRecords}
      </Button>
      <Button
        onClick={onClickCopyAll}
        hierarchy="secondary"
        size="small"
        has="iconLeft"
        aria-label={pt.actions.ariaLabelCopyRecords}
      >
        {pt.actions.copyAllRecords(filterContext.pagination.totalItems)}
      </Button>
    </ActionsContainer>
  )
}

export { Actions }
