import { styled } from '@grupoboticario/flora-react'

const Opacity = styled('div', {
  position: 'absolute',
  opacity: 0.1,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: '$medium',
  variants: {
    status: {
      success: {
        backgroundColor: '$success-alt',
      },
      warning: {
        backgroundColor: '$alert-alt',
      },
      error: {
        backgroundColor: '$error-standard',
      },
    },
  },
})

const InfoContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
  padding: '$6',
  width: '100%',
  variants: {
    status: {
      success: {
        color: '$success-standard',
      },
      warning: {
        color: '$alert-standard',
      },
      error: {
        color: '$error-standard',
      },
    },
  },

  span: {
    whiteSpace: 'pre-line',
  },
})

export { InfoContainer, Opacity }
