import { Flex, Image, Spacer, Text } from '@grupoboticario/flora-react'
import { DataBlock } from '@shared/components'
import { Description } from '@shared/components/data-block/description'
import { pt } from '@shared/locales'

import { ResellerClub } from './fidelity.types'

interface FidelityProps {
  club?: ResellerClub
}

const Fidelity = ({ club }: FidelityProps): JSX.Element => {
  const description = club?.isMcm
    ? pt.resellerList.detailFidelityMcmTitle
    : pt.resellerList.detailFidelityTitle

  return (
    <DataBlock
      renderContentOnly
      content={
        <Flex css={{ height: '100%', width: '100%' }} align="center">
          <Flex direction="column">
            <Description description={description} />
            <Flex align="center" gap="$4">
              <Text
                as="p"
                size="bodyLargeStandardMedium"
                color="$nonInteractivePredominant"
                weight="medium"
              >
                {club?.value}
              </Text>
            </Flex>
          </Flex>
          <Spacer />
          {club?.imageUrl && <Image src={club?.imageUrl} alt={club?.value} size={40} />}
        </Flex>
      }
    />
  )
}

export { Fidelity, type FidelityProps }
