import { styled } from '@grupoboticario/flora-react'

const TableHead = styled('thead', {
  background: '$backgroundSecondary',
  position: 'sticky',
  top: 0,
  zIndex: 1,
})

export { TableHead }
