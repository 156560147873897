import React from 'react'

import { Tag, TagProps } from '@grupoboticario/flora-react'

interface SectorTagProps {
  variant?: TagProps['variant']
  sector: string | number
}

const SectorTag: React.FC<SectorTagProps> = ({ sector, variant }) => (
  <Tag variant={variant} css={{ fontWeight: '$regular', flex: '0 0 auto' }}>
    {sector}
  </Tag>
)

export { SectorTag }
