import { type DataTablePagination } from '@shared/components'
import { useFiltersContext } from '@shared/providers'

const usePagination = (): DataTablePagination => {
  const { changePagination, pagination } = useFiltersContext()

  return {
    onItemsPerPageChange: (itemsPerPage: number) => changePagination({ pageSize: itemsPerPage }),
    onPaginate: (page) => changePagination({ page }),
    totalRecords: pagination.totalItems,
    itemsPerPage: pagination.pageSize,
    page: pagination.page,
  }
}

export { usePagination }
