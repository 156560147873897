import { Fragment } from 'react'

import { TabList } from '@grupoboticario/flora-react'

import { useSectorsContext } from '@sectors-filter/providers'
import { useEvents } from '@shared/events'
import { pt } from '@shared/locales'

import { useTypeSectorsFilter } from '../../hooks'
import { SectorType } from '../../sectors-filter.types'

import { Tab, Tabs } from './sectors-type.styles'

const SectorsType: React.FC = () => {
  const { filterBySectorType, sectorType, portfolio } = useTypeSectorsFilter()
  const sectors = useSectorsContext()
  const events = useEvents()
  const isEmpty = sectors.filled && !sectors.items.length

  if (isEmpty || sectors.loading || sectors.error) {
    return <Fragment />
  }

  return (
    <Tabs onValueChange={filterBySectorType} value={sectorType}>
      <TabList aria-label={pt.sectorsFilter.ariaLabel}>
        <Tab value={SectorType.All} onClick={() => events.clickTab(SectorType.All)}>
          {pt.sectorsFilter.all}
        </Tab>

        <Tab
          value={SectorType.Responsible}
          onClick={() => events.clickTab(SectorType.Responsible)}
          disabled={!portfolio.hasResponsible}
        >
          {pt.sectorsFilter.responsible}
        </Tab>

        <Tab
          value={SectorType.Coresponsible}
          onClick={() => events.clickTab(SectorType.Coresponsible)}
          disabled={!portfolio.hasCoresponsible}
        >
          {pt.sectorsFilter.coresponsible}
        </Tab>
      </TabList>
    </Tabs>
  )
}

export { SectorsType }
