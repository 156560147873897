import React, { Fragment } from 'react'

import { useSectorsContext } from '@sectors-filter/providers'

import { ActiveSectors, SectorsManagement } from './modules'
import { Container, EndContainer } from './sectors-filter.styles'

const SectorsFilter: React.FC = () => {
  const { filled, items } = useSectorsContext()

  if (filled && !items.length) {
    return <Fragment />
  }

  return (
    <Container>
      <ActiveSectors />

      <EndContainer>
        <SectorsManagement />
      </EndContainer>
    </Container>
  )
}

export { SectorsFilter }
