import { styled } from '@grupoboticario/flora-react'

const StyledList = styled('ul', {
  listStyle: 'none',
  padding: '14px 0 0',
  width: '100%',
  marginBottom: 0,

  variants: {
    alternate: {
      true: {
        '& li:nth-child(odd)': {
          backgroundColor: '$backgroundSecondary',
        },
        '& li': {
          borderRadius: '$medium',
        },
      },
    },
  },
})

const ListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  flex: 1,
})

const Collapse = styled('div', {
  overflow: 'hidden',
  transition: 'height 300ms',
  boxSizing: 'content-box',
})

const ButtonContainer = styled('div', {
  margin: '$6 auto $2 auto',
  width: 'fit-content',
})

export { StyledList, Collapse, ButtonContainer, ListContainer }
