import { styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  flex: 1,
  borderRadius: '$medium',
  backgroundColor: '$nonInteractiveAltPredominant !important',
  outlineWidth: '2px',
  outlineStyle: 'solid',
  outlineColor: 'transparent',
  paddingTop: '$4',
  paddingBottom: '$4',
  paddingRight: '$4',
  transitionProperty: 'outline-color',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'ease-out',
  width: '100%',

  '@tablet': {
    paddingRight: '$8',
  },

  '&:focus-visible': {
    outlineColor: '$brand-1',
  },

  variants: {
    avatar: {
      true: {
        paddingLeft: '$19',

        '@tablet': {
          paddingLeft: '96px',
        },
      },

      false: {
        paddingLeft: '$4',

        '@tablet': {
          paddingLeft: '$8',
        },
      },
    },

    content: {
      string: {},

      element: {
        minHeight: '$18',
      },
    },
  },
})

const ContentContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})

export { Container, ContentContainer }
