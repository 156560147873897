import { styled } from '@grupoboticario/flora-react'
import { Table } from '@grupoboticario/flora-team-react-table'

const TableRow = styled(Table.Row, {
  transitionProperty: 'background-color',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'ease-out',

  '&:hover': {
    backgroundColor: '$nonPrimaryButtonHover !important',
  },
})

export { TableRow }
