import { Flex, styled } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  gap: '$6',

  '@tablet': {
    width: '700px',
  },
})

export { Container }
