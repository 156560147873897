import React from 'react'

import { useApiData } from '@shared/api'

import { LoadingState } from '@shared/components'
import { pt } from '@shared/locales'
import { type Reseller } from '../../reseller-list.types'
import { General } from './general'
import { Promotions } from './promotions'
import { Container } from './reseller-detail.styles'
import { ShoppingHistory } from './shopping-history'

interface ResellerDetailProps {
  reseller: Reseller
}

const ResellerDetail: React.FC<ResellerDetailProps> = ({ reseller }) => {
  const { loading } = useApiData({
    dataSource: 'updateResellerData',
    params: { resellerId: reseller.id },
  })

  return (
    <Container justify={loading ? 'center' : 'flex-start'}>
      {loading && <LoadingState {...pt.listTable.loadingState} />}
      {!loading && (
        <General
          currentCycle={reseller.currentCycle}
          resellerId={reseller.id}
          lastTabulation={reseller.lastTabulation}
        />
      )}
      {!loading && <ShoppingHistory resellerId={reseller.id} />}
      {!loading && <Promotions resellerId={reseller.id} />}
    </Container>
  )
}

export { ResellerDetail }
