import { type API } from '@shared/api'
import { type DataTableOrder } from '@shared/components'

type FilterField =
  | FilterFieldDropdown
  | FilterFieldDropdownAsync
  | FilterFieldDate
  | FilterFieldCheckbox
  | FilterFieldSwitch
  | FilterFieldInput

interface FilterFieldInput extends FilterFieldBase {
  type: 'input'
  style: 'currency'
}

interface FilterFieldDropdown extends FilterFieldBase, FilterFieldBaseOptions {
  type: 'dropdown'
  multiple?: boolean
}

interface FilterFieldDropdownAsync extends FilterFieldBase {
  type: 'dropdown-async'
  dataSource: keyof API
  multiple?: boolean
}

interface FilterFieldCheckbox extends FilterFieldBase, FilterFieldBaseOptions {
  type: 'checkbox'
}

interface FilterFieldSwitch extends FilterFieldBase {
  type: 'switch'
}

interface FilterFieldDate extends FilterFieldBase {
  type: 'date-range' | 'date'
  filters: [FilterName, FilterName]
  maxDays?: number
}

interface FilterFieldBase {
  id: string
  disabled?: boolean
  isDisabled?: (form: FilterValues) => boolean
  filters: [FilterName] | [FilterName, FilterName]
  label: string
  grid?: 'half' | 'full'
}

interface FilterFieldBaseOptions {
  options: FilterFieldOption[]
}

interface FilterFieldOption {
  label: string
  value: string
}

interface FilterFieldGroup {
  description: string
  fields: FilterField[]
}

interface FilterName {
  name: string
  label: string
}

type FilterValues<T = unknown> = Record<string, T>

interface Filters extends FilterValues {
  sectorId: number[]
}

interface Ordination {
  sortField: string
  sortOrder: DataTableOrder | ''
}

interface Pagination {
  page: number
  pageSize: number
  totalPages: number
  currentPage?: number
  totalItems: number
}

enum FieldTypes {
  DROPDOWN_ASYNC = 'dropdown-async',
  DROPDOWN = 'dropdown',
  DATE_RANGE = 'date-range',
  CHECKBOX = 'checkbox',
  SWITCH = 'switch',
  INPUT = 'input',
}

export type {
  FilterField,
  FilterFieldCheckbox,
  FilterFieldDate,
  FilterFieldDropdown,
  FilterFieldDropdownAsync,
  FilterFieldGroup,
  FilterFieldInput,
  FilterFieldOption,
  FilterFieldSwitch,
  FilterName,
  Filters,
  FilterValues,
  Ordination,
  Pagination,
}

export { FieldTypes }
